import { ReactElement, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { Helmet } from 'react-helmet-async';
import { ArtistContext } from 'context/ArtistContext';

export default function GoogleAnalytics(): ReactElement {
  const location = useLocation();
  useEffect(() => {
    if (ReactGA.isInitialized) {
      ReactGA.send(`${location.pathname}${location.search}`);
    }
  }, [location]);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { analyticsId } = artist;
  return (
    <Helmet>
      {analyticsId && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${analyticsId}`}
        />
      )}
      {analyticsId && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', "${analyticsId}");
          `}
        </script>
      )}
    </Helmet>
  );
}
