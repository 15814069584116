import { createContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { ArtistLibrary } from 'types';

const GET_SITE_LIBRARIES = gql`
  query GET_SITE_LIBRARIES {
    siteLibraries {
      id
      title
      isSpanish
      facebookLink
      tikTokLink
      instagramLink
      spotifyLink
      twitterLink
      youtubeLink
      pandoraLink
      appleMusicLink
      soundcloudLink
      siteCssBodyClass
      mailchimp
      logo {
        id
        url
      }
      bookingEmailOne
      bookingPhoneOne
      bookingNameOne
      miniBio
      analyticsId
      siteLibraryJson
      displayWhatsAppFloat
      youtubePlaylistId
      whatsAppFloatContactNumber
      labelName
      labelLink
      labelLogo {
        id
        url
      }
      customNavCtaText
      customNavCtaLink
      epkLink
      displayBlogNav
      navigation
      hasSplashPage
      logoSecondary {
        id
        url
      }
      bandsInTownEmailWidget
      paypalClientId
    }
  }
`;

export const ArtistContext = createContext<ArtistLibrary>({
  title: '',
  isSpanish: false,
  displayWhatsAppFloat: false,
  facebookLink: '',
  tikTokLink: '',
  instagramLink: '',
  spotifyLink: '',
  twitterLink: '',
  youtubeLink: '',
  siteCssBodyClass: '',
  mailchimp: '',
  logo: {
    url: '',
  },
  bookingEmailOne: '',
  bookingPhoneOne: '',
  bookingNameOne: '',
  miniBio: '',
  analyticsId: '',
  pandoraLink: '',
  appleMusicLink: '',
  soundcloudLink: '',
  youtubePlaylistId: '',
  whatsAppFloatContactNumber: '',
  labelName: '',
  labelLink: '',
  labelLogo: {
    url: '',
  },
  customNavCtaText: '',
  customNavCtaLink: '',
  epkLink: '',
  displayBlogNav: true,
  navigation: {},
  siteLibraryJson: {},
  hasSplashPage: false,
  logoSecondary: {
    url: '',
  },
  logoSecondaryLink: '',
  bandsInTownEmailWidget: '',
  paypalClientId: '',
});

export function ArtistProvider({ children }) {
  const { loading, data } = useQuery(GET_SITE_LIBRARIES);
  const artist = loading || !data.siteLibraries ? null : data.siteLibraries[0];

  return (
    <ArtistContext.Provider value={artist}>{children}</ArtistContext.Provider>
  );
}
