import React, { ReactElement, useContext } from 'react';
import './video-hero.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faSpotify,
  faInstagram,
  faFacebook,
  faTiktok,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import { ArtistContext } from 'context/ArtistContext';
import parse from 'html-react-parser';

interface videoHeroProps {
  cssClassNameWrapper?: string;
  cssClassNameContent?: string;
  titleVideo?: string;
  subtitleVideo?: string;
  displaySocialOverlay?: boolean;
  videoLink?: string;
  logoSecondary?: { url?: string };
}

export default function VideoHero(props: videoHeroProps): ReactElement {
  const {
    cssClassNameWrapper,
    cssClassNameContent,
    titleVideo,
    subtitleVideo,
    videoLink,
    displaySocialOverlay,
    logoSecondary,
  } = props;
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const {
    facebookLink,
    tikTokLink,
    instagramLink,
    spotifyLink,
    twitterLink,
    youtubeLink,
    logo,
  } = artist;

  return (
    <section
      className={`video-wrapper dark-section ${
        cssClassNameWrapper && cssClassNameWrapper
      }`}>
      <div
        className={`video-overlay-content ${
          cssClassNameContent && cssClassNameContent
        }`}>
        {(!!logo || !!logoSecondary) && (
          <img
            src={logoSecondary?.url || logo?.url}
            alt="studio"
            className="w-8 mx-auto block"
            style={{ maxWidth: '400px' }}
          />
        )}
        {!!titleVideo && (
          <h1 className="text-white mb-0 uppercase text-lg font-400">
            {titleVideo}
          </h1>
        )}
        {!!subtitleVideo && (
          <h2 className="my-0 text-md font-light">{parse(subtitleVideo)}</h2>
        )}
      </div>
      {!!displaySocialOverlay && (
        <div className="tagline-wrapper">
          <nav className="col-12 flex flex-row footer-social-icons justify-content-center">
            {!!instagramLink && (
              <a
                href={instagramLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faInstagram as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
            {!!spotifyLink && (
              <a
                href={spotifyLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faSpotify as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
            {!!facebookLink && (
              <a
                href={facebookLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faFacebook as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
            {!!twitterLink && (
              <a
                href={twitterLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faTwitter as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
            {!!youtubeLink && (
              <a
                href={youtubeLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faYoutube as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
            {!!tikTokLink && (
              <a
                href={tikTokLink}
                target="_blank"
                className="max-w-max mx-2 text-center"
                rel="noreferrer">
                <FontAwesomeIcon
                  icon={faTiktok as IconProp}
                  className="fa-fw"
                />
              </a>
            )}
          </nav>
        </div>
      )}
      {/* TODO: Fix fallbackImage for all */}
      {!!videoLink && (
        <div className="main">
          <div className="overlay" />
          <video src={videoLink} autoPlay loop muted playsInline />
        </div>
      )}
    </section>
  );
}
