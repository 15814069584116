import { ArtistContext } from 'context/ArtistContext';
import HomePage from 'pages/home';
import SplashPage from 'pages/splashpage/SplashPage';
import { useContext } from 'react';

export default function HomePageRender() {
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { hasSplashPage } = artist;
  if (hasSplashPage) return <SplashPage />;
  return <HomePage />;
}
