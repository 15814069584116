/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { ActMembersType } from 'types';
import { Link } from 'react-router-dom';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/loader/Loader';
import { Head } from '../../components/Head';

const GET_HOME = gql`
  query GET_HOME {
    homePages {
      talentSectionTitle
      homeStandoutText
      homeStandoutTextCssWrapperName
      heroSectionBackgroundImage {
        id
        url
      }
      parallaxImage {
        id
        url
      }

      bioSectionTitle
      bioSectionContent {
        raw
        html
        markdown
        text
      }
      bioSectionImage {
        id
        url
      }
      talents(first: 500) {
        memberSlug
        name
        instrument
        miniMemberBio
        fullMemberBio {
          html
        }
        imageMemberAvatar {
          id
          url
        }
        emailMemberLink
        epkMemberLink
        facebookMemberLink
        instagramMemberLink
        linkedinMemberLink
        memberBandsInTown
        phoneMemberLink
        primaryMember
        spotifyMemberLink
        tikTokMemberLink
        twitterMemberLink
        websiteMemberLink
        youtubeMemberLink
        memberImageGallery(first: 500) {
          id
          url
        }
        memberMusicReleaseGallery(first: 500) {
          id
          url
        }
      }
    }
  }
`;

export default function ArtistsPage() {
  const { loading, data } = useQuery(GET_HOME);
  if (loading) return <Loader height="60vh" />;
  const home = data.homePages[0];
  const {
    parallaxImage,
    talents,
    talentSectionTitle,
    homeStandoutText,
    homeStandoutTextCssWrapperName,
  } = home;

  return (
    <>
      <Head metaTitle={talentSectionTitle} />
      <Nav />

      {!!parallaxImage && (
        <div
          className="parallax-container accent"
          style={{
            maxHeight: '40vh',
          }}>
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImage.url})`,
            }}
          />
        </div>
      )}
      {!!talents && (
        <section className="container my-8">
          <div className="grid px-4 mx-0">
            <div className="col-12 md:col-4 lg:col-5 animate-col-width">
              <h3 className="text-3xl">{talentSectionTitle}</h3>
            </div>
            <div className="col-12 md:col-8 lg:col-7 grid align-items-center justify-content-end animate-col-width">
              {talents.map((talent: ActMembersType) => (
                <Link
                  to={`/artist/${talent.memberSlug}`}
                  className="talent-card col-12 md:col-6 lg:col-4 no-underline animate-col-width"
                  key={talent.memberSlug}>
                  <img
                    src={talent.imageMemberAvatar?.url}
                    alt=""
                    className="talent-card-image"
                  />
                  <h4 className="my-0 py-0 flex align-items-center">
                    <span>{talent.name}</span>
                    <FontAwesomeIcon
                      icon={faArrowRight as IconProp}
                      className="fa-fw"
                    />
                  </h4>
                </Link>
              ))}
            </div>
          </div>
        </section>
      )}
      {!!homeStandoutText && (
        <div
          className={`home-standout-text-wrapper gradient-bkg ${
            homeStandoutTextCssWrapperName && homeStandoutTextCssWrapperName
          }`}>
          <section className="container py-8 flex align-items-center justify-content-center">
            <h3 className="home-standout-text text-center w-10 md:w-6 mx-auto">
              {homeStandoutText}
            </h3>
          </section>
        </div>
      )}
      <Footer />
    </>
  );
}
