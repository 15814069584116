/* eslint-disable react/no-danger */
import { ReactElement, useContext, useState } from 'react';
import { ArtistContext } from 'context/ArtistContext';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface MailchimpProps {
  float?: boolean;
  title?: string;
  subtitle?: string;
}

function SimpleForm(_formData: any) {
  const artist = useContext(ArtistContext);
  const { mailchimp } = artist;
  return <div>{mailchimp && <MailchimpSubscribe url={mailchimp} />}</div>;
}

function CustomForm({ status, message, onValidated, isSpanishProp }) {
  let email: any;
  let name: any;

  const submit = () =>
    email &&
    name &&
    email.valueOf.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.valueOf,
      NAME: name.valueOf,
    });

  return (
    <div className="w-full p-4 border-round">
      {status === 'sending' && <div style={{ color: 'blue' }}>sending...</div>}
      {status === 'error' && (
        <div
          style={{ color: 'red' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          style={{ color: 'green' }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="grid">
        <div className="col-12">
          <div className="field-wrapper mb-4">
            <span className="p-float-label">
              <InputText
                type="text"
                id="Name"
                className="w-full theme-bg-white theme-border-blk"
                ref={node => (name = node)}
              />
              <label htmlFor="Name">{isSpanishProp ? 'Nombre' : 'Name'}</label>
            </span>
          </div>
        </div>
        <div className="col-12">
          <div className="field-wrapper mb-4">
            <span className="p-float-label">
              <InputText
                type="text"
                id="Email"
                className="w-full theme-bg-white theme-border-blk"
                ref={node => (email = node)}
              />
              <label htmlFor="Email">{isSpanishProp ? 'Email' : 'Email'}</label>
            </span>
          </div>
        </div>
      </div>
      <Button
        type="submit"
        className="theme-bg-white theme-border-blk w-12 md:w-8 theme-button mx-auto max-w-max block no-underline text-1xl font-bold"
        label={isSpanishProp ? 'Registrar' : 'Register'}
        onClick={submit}
      />
    </div>
  );
}

// use the render prop and your custom form
export default function Mailchimp(props: MailchimpProps): ReactElement {
  const { float, title, subtitle } = props;
  const [visibleMailchimpModal, setVisibleMailchimpModal] =
    useState<boolean>(false);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { mailchimp, isSpanish } = artist;
  return (
    <div>
      {float ? (
        <>
          <Button
            label={isSpanish ? 'Fan Club' : 'Fan Club'}
            icon="pi pi-envelope"
            className="fixed theme-bg-white theme-border-blk border-1 px-4 theme-button mx-auto max-w-max block no-underline"
            style={{ inset: 'auto auto 40px 40px', zIndex: 999 }}
            onClick={() => setVisibleMailchimpModal(true)}
          />
          <Dialog
            visible={visibleMailchimpModal}
            onHide={() => setVisibleMailchimpModal(false)}
            breakpoints={{ '960px': '75vw', '640px': '100vw' }}
            className="w-10 md:w-6 var-surface-invert"
            draggable={false}
            dismissableMask
            headerClassName="py-1 px-2">
            {!!mailchimp && (
              <MailchimpSubscribe
                url={mailchimp}
                render={({ subscribe, status, message }) => (
                  <div>
                    <SimpleForm
                      onSubmitted={(formData: any) => {
                        subscribe(formData);
                        setVisibleMailchimpModal(false);
                      }}
                    />
                    {status === 'sending' && (
                      <div style={{ color: 'blue' }}>
                        {isSpanish ? 'Enviando...' : 'Sending...'}
                      </div>
                    )}
                    {status === 'error' && (
                      <div
                        style={{ color: 'red' }}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    )}
                    {status === 'success' && (
                      <div style={{ color: 'green' }}>Subscribed !</div>
                    )}
                  </div>
                )}
              />
            )}
          </Dialog>
        </>
      ) : (
        <div className="w-11 md:w-full border-0 border-round-xl p-2 md:p-4 reverse-gradient theme-box-shadow relative z-5 mx-auto">
          {!!subtitle && (
            <h2 className="my-0 font-light py-0 uppercase">{subtitle}</h2>
          )}
          {!!title && (
            <h1 className="text-2xl md:text-5xl mt-0 mb-2">{title}</h1>
          )}
          {!!mailchimp && (
            <MailchimpSubscribe
              url={URL}
              render={({ subscribe, status, message }) => (
                <CustomForm
                  status={status}
                  message={message}
                  onValidated={formData => subscribe(formData)}
                  isSpanishProp={isSpanish}
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}
