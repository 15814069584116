/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import { ArtistContext } from 'context/ArtistContext';
import { ActMembersType, NavigationContactInfo } from 'types';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagram,
  faFacebook,
  faTiktok,
  faTwitter,
  faYoutube,
  faLinkedin,
  faSpotify,
  faSoundcloud,
} from '@fortawesome/free-brands-svg-icons';
import useViewport from 'hooks/useViewport';
import {
  faEnvelope,
  faArrowLeft,
  faGlobe,
  faDownload,
  faLocationDot,
  faPhone,
  faDiamondTurnRight,
} from '@fortawesome/free-solid-svg-icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import Loader from '../../components/loader/Loader';
import { Head } from '../../components/Head';
import 'swiper/css';
import 'swiper/css/effect-cards';
import './artist-page.scss';

const GET_HOME = gql`
  query GET_HOME {
    homePages {
      talentSectionTitle
      homeStandoutText
      homeStandoutTextCssWrapperName
      heroSectionBackgroundImage {
        id
        url
      }
      parallaxImage {
        id
        url
      }

      bioSectionTitle
      bioSectionContent {
        raw
        html
        markdown
        text
      }
      bioSectionImage {
        id
        url
      }
      talents(first: 500) {
        memberSlug
        name
        instrument
        memberBookingData
        miniMemberBio
        fullMemberBio {
          html
        }
        imageMemberAvatar {
          id
          url
        }
        emailMemberLink
        epkMemberLink
        facebookMemberLink
        instagramMemberLink
        linkedinMemberLink
        memberBandsInTown
        phoneMemberLink
        primaryMember
        spotifyMemberLink
        tikTokMemberLink
        memberSoundcloudLink
        twitterMemberLink
        websiteMemberLink
        youtubeMemberLink
        memberImageGallery(first: 500) {
          id
          url
        }
        memberMusicReleaseGallery(first: 500) {
          id
          url
        }
      }
    }
  }
`;

export default function ArtistPage() {
  const { isMobile } = useViewport();
  const { artistPageUrlParam } = useParams();
  const { loading, data } = useQuery(GET_HOME);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { logo, isSpanish } = artist;
  if (loading) return <Loader height="60vh" />;
  const home = data.homePages[0];
  const {
    parallaxImage,
    bioSectionTitle,
    bioSectionContent,
    bioSectionImage,
    talents,
    tourSectionTitle,
    talentSectionTitle,
    homeStandoutText,
    homeStandoutTextCssWrapperName,
  } = home;

  const talent = talents.find(
    (talent: ActMembersType) => talent.memberSlug === artistPageUrlParam,
  );

  return (
    <>
      <Head metaTitle={tourSectionTitle} />
      <Nav />
      <section className="container">
        <div className="w-full mx-auto text-center my-2 md:mt-0 mb-2">
          <Link
            to="/artists"
            className="text-link uppercase no-underline justify-content-center max-w-max">
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              className="fa-fw mr-2"
            />
            <span>{isSpanish ? 'Todos Artistas' : 'All Artists'}</span>
          </Link>
        </div>
        <div
          className="col-12 mx-auto animate-col-width md:col-8"
          key={talent.id}>
          <div className="member-card">
            <div className="relative">
              {!!talent.primaryMember && (
                <p className="talent-primary-badge">
                  {isSpanish ? 'Exclusivo' : 'Exclusive'}
                </p>
              )}
              {!!talent.imageMemberAvatar && (
                <img
                  src={talent.imageMemberAvatar.url}
                  alt={(talent.name && talent.name) || ''}
                  className="block"
                  style={{
                    height: isMobile ? '300px' : '800px',
                    transition: 'height 200ms ease',
                  }}
                />
              )}
            </div>
            <div className="member-card-content w-11 mx-auto">
              {!!talent.name && (
                <h1 className="uppercase mb-2 gradient-text">{talent.name}</h1>
              )}
              {talent.instrument && (
                <p className="text-sm mt-0 py-0 mb-0">{talent.instrument}</p>
              )}
              {talent.miniMemberBio && (
                <p className="text-sm mt-0 py-0 mb-2 text-center uppercase">
                  {talent.miniMemberBio}
                </p>
              )}
              <nav className="member-card-social-icons mx-auto block max-w-max px-0 mt-1 mb-3">
                <div className="flex flex-row align-items-center mb-1">
                  {!!talent.instagramMemberLink && (
                    <a
                      href={talent.instagramMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 text-center ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faInstagram as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.facebookMemberLink && (
                    <a
                      href={talent.facebookMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faFacebook as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.twitterMemberLink && (
                    <a
                      href={talent.twitterMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faTwitter as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.youtubeMemberLink && (
                    <a
                      href={talent.youtubeMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faYoutube as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.tikTokMemberLink && (
                    <a
                      href={talent.tikTokMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faTiktok as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.memberSoundcloudLink && (
                    <a
                      href={talent.memberSoundcloudLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faSoundcloud as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.linkedinMemberLink && (
                    <a
                      href={talent.linkedinMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faLinkedin as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.emailMemberLink && (
                    <a
                      href={`mailto:${talent.emailMemberLink}`}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faEnvelope as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.spotifyMemberLink && (
                    <a
                      href={talent.spotifyMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faSpotify as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.websiteMemberLink && (
                    <a
                      href={talent.websiteMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faGlobe as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                  {!!talent.epkMemberLink && (
                    <a
                      href={talent.epkMemberLink}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faDownload as IconProp}
                        className="fa-fw"
                      />
                    </a>
                  )}
                </div>
                <div className="">
                  {/* {!!talent.phoneMemberLink && (
                    <a
                      href={`tel:${talent.phoneMemberLink}`}
                      target="_blank"
                      className="max-w-max mr-2 ml-0"
                      rel="noreferrer">
                      <FontAwesomeIcon
                        icon={faPhone as IconProp}
                        className="fa-fw"
                      />
                      <span className="ml-2">{talent.phoneMemberLink}</span>
                    </a>
                  )} */}
                  {/* {!!memberBookingData.contactItems.map(memberBookingItem => (
                    <p>{}</p>
                  ))} */}
                </div>
              </nav>

              {talent?.memberBookingData?.contactItems.map(
                (contactItem: NavigationContactInfo) => (
                  <div className="grid w-full flex align-items-center justify-content-center">
                    <div className="col-12 flex align-items-center flex-column">
                      {!!contactItem.name && (
                        <h4 className="font-bold text-xl m-0 flex flex-column align-items-center text-center justify-content-center mb-2">
                          <span className="block mx-auto">
                            {contactItem.name}
                          </span>
                          <span className="opacity-70 block text-xs mx-auto uppercase">
                            {contactItem.title}
                          </span>
                        </h4>
                      )}
                      {!!contactItem.email && (
                        <p className="flex align-items-center mt-0 mb-2">
                          <FontAwesomeIcon
                            icon={faEnvelope as IconProp}
                            className="fa-fw mr-2"
                          />
                          <span>{contactItem.email}</span>
                        </p>
                      )}
                      {!!contactItem.phone && (
                        <p className="flex align-items-center mt-0 mb-2">
                          <FontAwesomeIcon
                            icon={faPhone as IconProp}
                            className="fa-fw mr-2"
                          />
                          <span>{contactItem.phone}</span>
                        </p>
                      )}
                      {!!contactItem.address && (
                        <div className="flex flex-row align-items-center">
                          <p>
                            <FontAwesomeIcon
                              icon={faLocationDot as IconProp}
                              className="fa-fw mr-2"
                            />
                          </p>
                          <p className="flex flex-column">
                            <span>{contactItem.address}</span>
                            <span>{contactItem.cityStateZip}</span>
                          </p>
                          <a
                            href={contactItem.googleAddressLink}
                            className="text-primary"
                            target="_blank"
                            rel="noreferrer">
                            <FontAwesomeIcon
                              icon={faDiamondTurnRight as IconProp}
                              className="fa-fw ml-2"
                            />
                          </a>
                        </div>
                      )}
                    </div>
                    {!!contactItem.imageOne && (
                      <div className="col-12 md:col-6 animate-col-width">
                        <img
                          src={contactItem.imageOne}
                          alt=""
                          className="block w-12 border-round mb-4
                            "
                        />
                      </div>
                    )}
                  </div>
                ),
              )}

              {!!talent.fullMemberBio?.html && (
                <p className="body-parsed-text text-sm mt-0 py-0 mb-2">
                  {parse(talent.fullMemberBio.html)}
                </p>
              )}
              {!!talent.memberImageGallery && (
                <Swiper
                  effect="cards"
                  grabCursor
                  modules={[EffectCards]}
                  className="album-swiper my-8">
                  {talent.memberImageGallery.map(memberImageGalleryItem => (
                    <SwiperSlide
                      key={memberImageGalleryItem.url}
                      className=""
                      style={{ transform: 'scale(0.975)' }}>
                      <img
                        src={memberImageGalleryItem.url}
                        alt=""
                        className="mb-0 block border-round"
                        style={{
                          maxHeight: '1000px',
                          objectFit: 'cover',
                          aspectRatio: 1,
                        }}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              )}
              {!!talent.memberBandsInTown && (
                <section className="container my-4 dark-section">
                  <h2 className="text-3xl md:text-6xl my-2 text-center">
                    {tourSectionTitle && tourSectionTitle}
                  </h2>
                  {parse(talent.memberBandsInTown)}
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
      {!!homeStandoutText && (
        <div
          className={`home-standout-text-wrapper gradient-bkg my-8 ${
            homeStandoutTextCssWrapperName && homeStandoutTextCssWrapperName
          }`}>
          <section className="container py-8 flex align-items-center justify-content-center">
            <h3 className="home-standout-text text-center w-10 md:w-6 mx-auto">
              {homeStandoutText}
            </h3>
          </section>
        </div>
      )}
      {!!talents && (
        <section className="container my-8 px-4">
          <div className="grid">
            <div className="col-12 lg:col-2 flex flex-column align-items-center justify-items-center lg:justify-content-start">
              <img
                src={logo?.url}
                alt=""
                className="w-6 md:w-5 lg:w-8 block animate-col-width "
              />
              <p>{talentSectionTitle}</p>
            </div>
            <div className="col-12 lg:col-10 grid animate-col-width ">
              {talents.map((talent: ActMembersType) => (
                <Link
                  to={`/artist/${talent.memberSlug}`}
                  className="talent-card col-12 md:col-6 lg:col-4 animate-col-width  no-underline flex align-items-center justify-content-center flex-column"
                  key={talent.memberSlug}>
                  <img
                    src={talent.imageMemberAvatar?.url}
                    alt=""
                    className="mini-avatar block mx-auto"
                    style={{
                      objectFit: 'cover',
                      aspectRatio: 1,
                      height: '220px',
                      width: '220px',
                      borderRadius: '50%',
                      transition: 'all 200ms ease',
                    }}
                  />
                  <p className="text-center mx-auto uppercase">{talent.name}</p>
                </Link>
              ))}
            </div>
          </div>
        </section>
      )}

      {!!bioSectionTitle && (
        <section className="container my-8 dark-section">
          <div className="w-10 md:w-6 mx-auto">
            {!!bioSectionImage && (
              <img
                src={bioSectionImage.url}
                alt={(bioSectionTitle && bioSectionTitle) || ''}
                className="w-full mx-auto"
                style={{ objectFit: 'cover' }}
              />
            )}
            {!!bioSectionTitle && (
              <h2 className="text-center">{bioSectionTitle}</h2>
            )}
            {!!bioSectionContent?.html && (
              <div className="body-parsed-text">
                {parse(bioSectionContent.html)}
              </div>
            )}
          </div>
        </section>
      )}
      {!!parallaxImage && (
        <div className="parallax-container accent">
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImage.url})`,
            }}
          />
        </div>
      )}
      <Footer />
    </>
  );
}
