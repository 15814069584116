import { Piano, KeyboardShortcuts, MidiNumbers } from 'react-piano';
import 'react-piano/dist/styles.css';
import useViewport from 'hooks/useViewport';
import SoundfontProvider from './SoundfontProvider';
import './piano.scss';

export default function PianoComp() {
  const { isMobile } = useViewport();
  const firstNote = MidiNumbers.fromNote('c3');
  const lastNote = MidiNumbers.fromNote(isMobile ? 'c4' : 'c5');
  const keyboardShortcuts = KeyboardShortcuts.create({
    firstNote,
    lastNote,
    keyboardConfig: KeyboardShortcuts.HOME_ROW,
  });

  const audioContext = new (window.AudioContext || window.AudioContext)();
  const soundfontHostname = 'https://d1pzp51pvbm36p.cloudfront.net';

  return (
    <SoundfontProvider
      instrumentName="acoustic_grand_piano"
      audioContext={audioContext}
      hostname={soundfontHostname}
      render={({ isLoading, playNote, stopNote }) => (
        <section className="container overflow-hidden">
          <div className="flex mx-auto flex-column mt-8 mb-2">
            <h3 className="my-0 font-bold py-0 uppercase text-center text-3xl mx-auto px-4">
              Start your idea now & book your session to record it
            </h3>
            <div
              className={isMobile ? 'mt-8 mb-6' : 'my-0'}
              style={{
                transform: isMobile ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'all 200ms ease',
              }}>
              <Piano
                noteRange={{ first: firstNote, last: lastNote }}
                playNote={playNote}
                stopNote={stopNote}
                disabled={isLoading}
                keyboardShortcuts={keyboardShortcuts}
                width={isMobile ? 380 : 800}
              />
            </div>
          </div>
        </section>
      )}
    />
  );
}
