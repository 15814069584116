import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { ArtistContext } from 'context/ArtistContext';
import { EventType } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faMapLocationDot,
  faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import parse from 'html-react-parser';
import Moment from 'react-moment';
import './event.scss';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import ReactGA from 'react-ga4';
import Loader from '../../components/loader/Loader';

const GET_EVENTS = gql`
  query GET_EVENTS {
    events(first: 100) {
      eventAddress
      eventAddressGoogleMapLink
      eventCityState
      eventEndDateTime
      eventDescription {
        html
      }
      eventFeatured
      eventFlyer {
        id
        url
      }
      eventLinkButtonText
      eventShortDescription
      eventSlug
      eventStartDateTime
      eventTicketLinkDestination
      eventTitle
      eventVenueName
    }
  }
`;

export default function EventPage() {
  const { eventPageUrlParam } = useParams();
  const artist = useContext(ArtistContext);
  const { loading, data } = useQuery(GET_EVENTS);
  if (loading) return <Loader height="60vh" />;
  const { events } = data;
  const event = events.find(
    (event: EventType) => event.eventSlug === eventPageUrlParam,
  );
  const {
    eventTitle,
    eventFlyer,
    eventAddress,
    eventAddressGoogleMapLink,
    eventCityState,
    eventEndDateTime,
    eventDescription,
    eventLinkButtonText,
    eventStartDateTime,
    eventTicketLinkDestination,
    eventVenueName,
  } = event;
  if (!artist) return <div />;
  const { isSpanish } = artist;

  return (
    <>
      <Nav />
      <section className="container dark-section mx-auto">
        <div className="relative w-10 mx-auto mt-2 mb-4 md:my-8">
          <div className="w-full mx-auto text-center my-2 md:mt-0 mb-2">
            <Link
              to="/events"
              onClick={() =>
                ReactGA.event({
                  category: 'Link',
                  action: `Visit All Events`,
                  label: `Visit All Events`,
                })
              }
              className="text-link uppercase no-underline justify-content-center max-w-max">
              <FontAwesomeIcon
                icon={faArrowLeft as IconProp}
                className="fa-fw mr-2"
              />
              <span>{isSpanish ? 'Todos Eventos' : 'All Events'}</span>
            </Link>
          </div>
          <div className="gradient-bkg">
            <div className="outline-text-wrapper py-6 px-4 w-full">
              <h1 className="outline-text text-center text-2xl text-center md:text-4xl pb-0 my-0 flex flex-column">
                {!!eventTitle && <span>{eventTitle}</span>}
                {!!eventCityState && (
                  <span className="text-2xl">{eventCityState}</span>
                )}
              </h1>
            </div>
          </div>
        </div>
        <div className="grid w-10 mx-auto">
          <div className="col-12 md:col-6 lg:col-5">
            {!!eventTitle && <h2>{eventTitle}</h2>}
            {!!eventTicketLinkDestination && (
              <a
                href={eventTicketLinkDestination}
                target="_blank"
                className="theme-bg-white theme-border-blk border-1 px-8 px-4 theme-button max-w-max block no-underline mb-8"
                rel="noreferrer">
                {eventLinkButtonText}
              </a>
            )}
            <div className="event-content-wrapper mb-4">
              {!!eventVenueName && <h3 className="mb-0">{eventVenueName}</h3>}
              {!!eventAddress && (
                <p className="flex align-items-center">
                  {eventAddress}
                  <a
                    href={eventAddressGoogleMapLink}
                    target="_blank"
                    rel="noreferrer">
                    <FontAwesomeIcon
                      icon={faMapLocationDot as IconProp}
                      className="fa-fw ml-2"
                    />
                  </a>
                </p>
              )}
              {!!eventDescription && (
                <div className="body-parsed-text">
                  {parse(eventDescription.html)}
                </div>
              )}
              <p className="flex flex-column text-sm my-0">
                {!!eventStartDateTime && (
                  <span>
                    <Moment format="MMMM DD, YYYY • h:mm a">
                      {eventStartDateTime}
                    </Moment>
                  </span>
                )}
                {!!eventEndDateTime && (
                  <span>
                    <Moment format="MMMM DD, YYYY • h:mm a">
                      {eventEndDateTime}
                    </Moment>
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="col-12 md:col-6 lg:col-7">
            <img src={eventFlyer.url} alt="" className="block w-full mx-auto" />
          </div>
        </div>
        <div className="w-full">
          {!!eventTicketLinkDestination && (
            <a
              href={eventTicketLinkDestination}
              onClick={() =>
                ReactGA.event({
                  category: 'Conversion',
                  action: `Purchase ${eventTitle} - Link ${eventTicketLinkDestination}`,
                  label: `Purchase ${eventTitle} - Link ${eventTicketLinkDestination}`,
                })
              }
              target="_blank"
              className="theme-bg-white theme-border-blk border-1 px-8 px-4 theme-button max-w-max block no-underline my-8 mx-auto"
              rel="noreferrer">
              {eventLinkButtonText}
            </a>
          )}
        </div>
        <div className="w-full mx-auto text-center my-8">
          <Link
            to="/events"
            onClick={() =>
              ReactGA.event({
                category: 'Link',
                action: `Visit All Events`,
                label: `Visit All Events`,
              })
            }
            className="text-link uppercase no-underline justify-content-center max-w-max">
            <FontAwesomeIcon
              icon={faArrowLeft as IconProp}
              className="fa-fw mr-2"
            />
            <span>{isSpanish ? 'Todos Eventos' : 'All Events'}</span>
          </Link>
        </div>
      </section>
      <Footer />
    </>
  );
}
