/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-danger */
import React, { useContext, useEffect, useState } from 'react';
import useViewport from 'hooks/useViewport';
import { gql, useQuery } from '@apollo/client';
import parse from 'html-react-parser';
import '../home.scss';
import Slider from 'react-slick';
import { Fade } from 'react-awesome-reveal';
import { ArtistContext } from 'context/ArtistContext';
import { ActMembersType, featureSectionComponentType } from 'types';
import Whatsapp from 'components/whatsapp/Whatsapp';
import EventGridSlider from 'components/eventGridSlider/EventGridSlider';
import { Link } from 'react-router-dom';
import BlogGridSlider from 'components/blogGridSlider/BlogGridSlider';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VideoCategory from 'components/videoCategory/VideoCategory';
import MagicGrid from 'react-magic-grid';
import { Head } from '../../../components/Head';
import Loader from '../../../components/loader/Loader';
import FeatureAlbum from '../FeatureAlbum';
import VideoHomePage from '../videos/VideoHomePage';
import Mailchimp from '../../../components/mailchimp/Mailchimp';
import VideoHero from '../VideoHero';

const GET_HOME = gql`
  query GET_HOME {
    homePages {
      id
      themeName
      title
      subtitle
      heroCtaLabel
      heroCtaLink
      bandsInTown
      heroImage {
        id
        url
      }
      hasVideoHero
      displaySocialOverlay
      videoLink {
        id
        url
      }
      tourSectionTitle
      talentSectionTitle
      blogSectionTitle
      homeJsonData
      homeStandoutText
      homeStandoutTextCssWrapperName
      heroSectionBackgroundImage {
        id
        url
      }
      parallaxImage {
        id
        url
      }
      parallaxImageTwo {
        id
        url
      }
      headlinePrimary {
        raw
        html
        markdown
        text
      }
      gallery(first: 500) {
        id
        url
      }
      bioSectionTitle
      bioSectionContent {
        raw
        html
        markdown
        text
      }
      bioSectionImage {
        id
        url
      }
      albumMap
      featureSectionComponents {
        featureSectionButtonLink
        featureSectionButtonText
        featureSectionColumnCount
        featureSectionCssClassNameWrapper
        featureSectionHtmlIdWrapper
        featureSectionSubtitle
        featureSectionTitle
        featureSectionContent {
          raw
          html
          markdown
          text
        }
        featureSectionBackgroundImage {
          id
          url
        }
        featureSectionDisplayImage {
          id
          url
        }
      }
      displayEvents
      displayEventsStyleGridOrSlider
      talents {
        name
        instrument
        miniMemberBio
        emailMemberLink
        facebookMemberLink
        fullMemberBio {
          html
        }
        imageMemberAvatar {
          id
          url
        }
        instagramMemberLink
        linkedinMemberLink
        memberBandsInTown
        youtubeMemberLink
        phoneMemberLink
        linkedinMemberLink
        memberImageGallery(first: 500) {
          id
          url
        }
        memberMusicReleaseGallery(first: 500) {
          id
          url
        }
        memberSlug
        miniMemberBio
        phoneMemberLink
        primaryMember
        tikTokMemberLink
        youtubeMemberLink
      }
      # localizeTest
    }
  }
`;

function VideoTheme() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });
  const { isMobile } = useViewport();
  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 150 ? setIsScrolled(true) : setIsScrolled(false);
  };

  document.body.classList.add('animate-body');
  isScrolled && document.body.classList.add('animate-body-active');
  !isScrolled && document.body.classList.remove('animate-body-active');
  const { loading, data } = useQuery(GET_HOME);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { mailchimp, displayBlogNav, bandsInTownEmailWidget, logo } = artist;
  if (loading) return <Loader height="60vh" />;
  const home = data.homePages[0];
  const {
    themeName,
    title,
    subtitle,
    headlinePrimary,
    heroCtaLabel,
    heroCtaLink,
    bandsInTown,
    heroImage,
    gallery,
    heroSectionBackgroundImage,
    videoLink,
    hasVideoHero,
    parallaxImage,
    parallaxImageTwo,
    displaySocialOverlay,
    cssClassHeroNameWrapper,
    cssClassHeroNameContent,
    bioSectionTitle,
    bioSectionContent,
    bioSectionImage,
    albumMap,
    featureSectionComponents,
    displayEvents,
    displayEventsStyleGridOrSlider,
    talents,
    tourSectionTitle,
    talentSectionTitle,
    homeStandoutText,
    homeStandoutTextCssWrapperName,
    blogSectionTitle,
    homeJsonData,
  } = home;

  console.log(homeJsonData);

  const finalImages = gallery.map((image: { url: any }) => image.url);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };

  return (
    <>
      <Head metaTitle={title} />
      <Nav />
      <Whatsapp />
      {/* {!!finalImages.length && themeName !== 'gridGallery' && (
        <Slider {...settings} className="">
          {finalImages.map(image => (
            <img src={image} alt="" className="w-12 slider-image" key={image} />
          ))}
        </Slider>
      )} */}
      {hasVideoHero ? (
        <VideoHero
          cssClassNameWrapper={cssClassHeroNameWrapper}
          cssClassNameContent={cssClassHeroNameContent}
          titleVideo={title}
          subtitleVideo={headlinePrimary.html}
          videoLink={videoLink.url}
          displaySocialOverlay={displaySocialOverlay}
          ctaLabel={heroCtaLabel}
          ctaLink={heroCtaLink}
          displayContent={false}
        />
      ) : (
        <>
          <div
            className={`hero-bg py-8 ${
              cssClassHeroNameWrapper && cssClassHeroNameWrapper
            }`}
            style={{
              backgroundImage:
                heroSectionBackgroundImage &&
                `url(${heroSectionBackgroundImage.url})`,
            }}>
            <section className="container w-full my-8">
              <div className="grid mx-auto align-items-center">
                <div className="headlinePrimary col-10 mx-auto">
                  {logo && (
                    <img
                      src={logo.url}
                      alt="studio"
                      className="w-8 block mx-auto"
                      style={{ maxWidth: '400px' }}
                    />
                  )}
                  {!!headlinePrimary?.html && (
                    <Fade
                      triggerOnce
                      direction="left"
                      className="text-center mx-auto w-full block font-bold uppercase text-3xl md:text-5xl xl:text-7xl mb-0 nested-pb-0">
                      {parse(headlinePrimary?.html)}
                    </Fade>
                  )}
                  {subtitle && (
                    <Fade
                      triggerOnce
                      direction="right"
                      className="text-center mx-auto w-full block font-light uppercase text-3xl gradient-text">
                      {parse(subtitle)}
                    </Fade>
                  )}
                </div>
              </div>
            </section>
          </div>
          {!!heroImage && (
            <Fade triggerOnce className="w-full mx-auto block" direction="up">
              <img
                src={heroImage.url}
                alt="studio"
                className="w-full mx-auto block"
              />
            </Fade>
          )}
        </>
      )}
      {!!mailchimp && (
        <div className="gradient-bkg m-0 p-0">
          <section className="container py-8 flex align-items center jutsify-content-center">
            <div className="flex flex-column align-items-center text-center w-full md:w-6 mx-auto">
              <Fade triggerOnce direction="up" className="w-full">
                <Mailchimp float={false} title={title} subtitle={subtitle} />
              </Fade>
            </div>
          </section>
        </div>
      )}
      {!!bandsInTownEmailWidget && (
        <div className="gradient-bkg m-0 px-4">
          <section className="container py-8 flex align-items center jutsify-content-center">
            <div className="flex flex-column align-items-center text-center w-full md:w-6 mx-auto">
              <Fade triggerOnce direction="up" className="w-full">
                {parse(bandsInTownEmailWidget)}
              </Fade>
            </div>
          </section>
        </div>
      )}
      {!!displayEvents && (
        <section className="container my-8">
          <h2 className="text-3xl md:text-6xl my-2 text-center">
            {tourSectionTitle && tourSectionTitle}
          </h2>
          <EventGridSlider
            displayEventsStyleGridOrSlider={displayEventsStyleGridOrSlider}
          />
        </section>
      )}
      {!!parallaxImage && (
        <div className="parallax-container accent">
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImage.url})`,
            }}
          />
        </div>
      )}
      {!!featureSectionComponents && (
        <div>
          {featureSectionComponents.map(
            (featureSectionComponent: featureSectionComponentType) => (
              <section
                className={`background-image-featured ${featureSectionComponent.featureSectionCssClassNameWrapper}`}
                id={featureSectionComponent.featureSectionHtmlIdWrapper}
                key={featureSectionComponent.featureSectionHtmlIdWrapper}
                style={{
                  backgroundImage:
                    featureSectionComponent.featureSectionBackgroundImage &&
                    `url(${featureSectionComponent.featureSectionBackgroundImage.url})`,
                }}>
                <div className="container mx-auto w-full py-8 px-4 md:px-2 relative z-2">
                  {featureSectionComponent.featureSectionColumnCount === 1 && (
                    <div className="mx-auto w-12 md:w-6 flex align-items-center flex-column">
                      {!!featureSectionComponent.featureSectionDisplayImage && (
                        <Fade triggerOnce direction="up">
                          <img
                            src={
                              featureSectionComponent.featureSectionDisplayImage
                                .url
                            }
                            className="w-full block mb-0"
                            style={{ objectFit: 'cover', maxHeight: '340px' }}
                            alt={
                              (featureSectionComponent.featureSectionTitle &&
                                featureSectionComponent.featureSectionTitle) ||
                              ''
                            }
                          />
                        </Fade>
                      )}
                      {!!featureSectionComponent.featureSectionTitle && (
                        <h2 className="text-3xl mb-0 mt-0">
                          {featureSectionComponent.featureSectionTitle}
                        </h2>
                      )}
                      {!!featureSectionComponent.featureSectionSubtitle && (
                        <h3 className="mb-1">
                          {featureSectionComponent.featureSectionSubtitle}
                        </h3>
                      )}
                      {!!featureSectionComponent.featureSectionContent && (
                        <div
                          className="feature-section-html text-center body-parsed-text
                        ">
                          {parse(
                            featureSectionComponent.featureSectionContent.html,
                          )}
                        </div>
                      )}
                      {!!featureSectionComponent?.featureSectionButtonLink && (
                        <div>
                          {featureSectionComponent?.featureSectionButtonLink?.includes(
                            'http',
                          ) ? (
                            <a
                              href={
                                featureSectionComponent.featureSectionButtonLink
                              }
                              target="_blank"
                              className="theme-border-white text-white border-1 px-4 md:px-6 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full text-2xl"
                              rel="noreferrer">
                              {featureSectionComponent.featureSectionButtonText}
                            </a>
                          ) : (
                            <Link
                              to={`${featureSectionComponent.featureSectionButtonLink}`}
                              className="theme-border-white text-white border-1 px-4 md:px-6 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full text-2xl">
                              {featureSectionComponent.featureSectionButtonText}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {featureSectionComponent.featureSectionColumnCount === 2 && (
                    <div className="grid">
                      <div className="col-12 md:col-6">
                        {!!featureSectionComponent.featureSectionDisplayImage && (
                          <img
                            src={
                              featureSectionComponent.featureSectionDisplayImage
                                .url
                            }
                            className="w-full block"
                            style={{ objectFit: 'cover', maxHeight: '340px' }}
                            alt={
                              (featureSectionComponent.featureSectionTitle &&
                                featureSectionComponent.featureSectionTitle) ||
                              ''
                            }
                          />
                        )}
                      </div>
                      <div className="col-12 md:col-6">
                        <div className="w-12 md:w-8">
                          {!!featureSectionComponent.featureSectionTitle && (
                            <h2>
                              {featureSectionComponent.featureSectionTitle}
                            </h2>
                          )}
                          {!!featureSectionComponent.featureSectionSubtitle && (
                            <h3>
                              {featureSectionComponent.featureSectionSubtitle}
                            </h3>
                          )}
                          {!!featureSectionComponent.featureSectionSubtitle && (
                            <h3>
                              {featureSectionComponent.featureSectionSubtitle}
                            </h3>
                          )}
                          {!!featureSectionComponent.featureSectionContent && (
                            <div className="feature-section-html body-parsed-text">
                              {parse(
                                featureSectionComponent.featureSectionContent
                                  .html,
                              )}
                            </div>
                          )}
                          {featureSectionComponent?.featureSectionButtonLink?.includes(
                            'http',
                          ) ? (
                            <a
                              href={
                                featureSectionComponent.featureSectionButtonLink
                              }
                              target="_blank"
                              className="theme-border-white text-white border-1 px-4 md:px-4 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full"
                              rel="noreferrer">
                              {featureSectionComponent.featureSectionButtonText}
                            </a>
                          ) : (
                            <Link
                              to={`${featureSectionComponent.featureSectionButtonLink}`}
                              className="theme-border-white text-white border-1 px-4 md:px-4 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full">
                              {featureSectionComponent.featureSectionButtonText}
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </section>
            ),
          )}
        </div>
      )}
      <VideoCategory />
      {!!talents && talents.length >= 1 && (
        <div className="relative texture-background overflow-hidden">
          <section className="container my-8 relative">
            <div className="grid mx-0 px-4">
              <div className="col-12 md:col-4 lg:col-5 animate-col-width">
                {talentSectionTitle && (
                  <h3 className="text-3xl md:text-6xl text-left mt-0 ml-0 gradient-text uppercase max-w-max">
                    {talentSectionTitle}
                  </h3>
                )}
              </div>
              <div className="col-12 md:col-8 lg:col-7 grid align-items-center justify-content-end animate-col-width">
                {talents.map((talent: ActMembersType) => (
                  <Link
                    to={`/artist/${talent.memberSlug}`}
                    className="talent-card col-12 md:col-4 no-underline mx-auto"
                    key={talent.memberSlug}>
                    <img
                      src={talent.imageMemberAvatar?.url}
                      alt="mx-auto"
                      className="talent-card-image"
                    />
                    <h4 className="my-0 py-0">
                      <span>{talent.name}</span>
                      <FontAwesomeIcon
                        icon={faArrowRight as IconProp}
                        className="fa-fw"
                      />
                    </h4>
                  </Link>
                ))}
              </div>
            </div>
          </section>
        </div>
      )}
      {!!bandsInTown && (
        <section className="container my-4 dark-section">
          <h2 className="text-3xl md:text-6xl my-2 text-center">
            {tourSectionTitle && tourSectionTitle}
          </h2>
          {parse(bandsInTown)}
        </section>
      )}
      {!!finalImages.length && themeName !== 'gridGallery' && (
        <Slider {...settings} className="">
          {finalImages.map(image => (
            <img src={image} alt="" className="w-12 slider-image" key={image} />
          ))}
        </Slider>
      )}
      {/* {hasVideoHero ? (
        <VideoHero
          cssClassNameWrapper={cssClassHeroNameWrapper}
          cssClassNameContent={cssClassHeroNameContent}
          titleVideo={title}
          subtitleVideo={headlinePrimary.html}
          videoLink={videoLink.url}
          displaySocialOverlay={displaySocialOverlay}
          ctaLabel={heroCtaLabel}
          ctaLink={heroCtaLink}
        />
      ) : (
        <div
          className={`hero-bg ${
            cssClassHeroNameWrapper && cssClassHeroNameWrapper
          }`}
          style={{
            backgroundImage:
              heroSectionBackgroundImage &&
              `url(${heroSectionBackgroundImage.url})`,
          }}>
          <section className="container w-full my-8">
            <div className="grid mx-auto align-items-center">
              {!!headlinePrimary?.html && (
                <div className="headlinePrimary text-3xl md:text-5xl xl:text-7xl col-10 md:col-6">
                  {logo ? (
                    <img
                      src={logo.url}
                      alt="studio"
                      className="w-8 block"
                      style={{ maxWidth: '400px' }}
                    />
                  ) : (
                    <h1 className="text-white mb-0 uppercase text-lg font-400">
                      {title}
                    </h1>
                  )}

                  {subtitle && (
                    <Fade triggerOnce direction="left">
                      {parse(subtitle)}
                    </Fade>
                  )}
                </div>
              )}
              {!!heroImage && (
                <Fade
                  triggerOnce
                  className="col-10 md:col-6 mx-auto block"
                  direction="right">
                  <img
                    src={heroImage.url}
                    alt="studio"
                    className="w-full mx-auto block"
                    style={{ maxWidth: '500px' }}
                  />
                </Fade>
              )}
            </div>
          </section>
        </div>
      )} */}
      {!!(themeName === 'gridGallery') && (
        <div className="my-8">
          {!!finalImages.length && (
            <MagicGrid items={finalImages.length}>
              {finalImages.map(image => (
                <img
                  src={image}
                  alt=""
                  className=""
                  style={{ maxWidth: '300px' }}
                  key={image}
                />
              ))}
            </MagicGrid>
          )}
        </div>
      )}

      <FeatureAlbum albumMap={albumMap} />
      <div className="my-0">
        <VideoHomePage />
      </div>
      {!!parallaxImageTwo && (
        <div className="parallax-container accent">
          <div
            className="image-parallax"
            style={{
              backgroundImage: `url(${parallaxImageTwo.url})`,
            }}
          />
        </div>
      )}
      {!!displayBlogNav && (
        <div className="relative texture-background texture-right overflow-hidden">
          <section className="container my-8 px-4 dark-section">
            <BlogGridSlider fromHomePage blogHeader={blogSectionTitle} />
          </section>
        </div>
      )}
      {!!bioSectionTitle && (
        <section className="container my-8 dark-section">
          <div className="w-10 md:w-6 mx-auto">
            {!!bioSectionImage && (
              <Fade triggerOnce direction="up">
                <img
                  src={bioSectionImage.url}
                  alt={(bioSectionTitle && bioSectionTitle) || ''}
                  className="w-full mx-auto"
                  style={{ objectFit: 'cover' }}
                />
              </Fade>
            )}
            {!!bioSectionTitle && (
              <h2 className="text-center">{bioSectionTitle}</h2>
            )}
            {!!bioSectionContent.html && (
              <div className="parsed-content body-parsed-text text-center">
                {parse(bioSectionContent.html)}
              </div>
            )}
          </div>
        </section>
      )}
      {!!homeStandoutText && (
        <div
          className={`home-standout-text-wrapper gradient-bkg ${
            homeStandoutTextCssWrapperName && homeStandoutTextCssWrapperName
          }`}>
          <section className="container py-8 flex align-items-center justify-content-center">
            {isMobile ? (
              <h3 className="home-standout-text text-shadow text-center mx-auto w-10 md:w-6">
                {homeStandoutText}
              </h3>
            ) : (
              <h3 className="home-standout-text text-shadow">
                <Fade
                  cascade
                  triggerOnce
                  duration={100}
                  className="text-center mx-auto w-10 md:w-6">
                  {homeStandoutText}
                </Fade>
              </h3>
            )}
          </section>
        </div>
      )}
      <VideoHero
        cssClassNameWrapper={cssClassHeroNameWrapper}
        cssClassNameContent={cssClassHeroNameContent}
        titleVideo={title}
        subtitleVideo={headlinePrimary.html}
        videoLink={videoLink.url}
        displaySocialOverlay={displaySocialOverlay}
        ctaLabel={heroCtaLabel}
        ctaLink={heroCtaLink}
        displayContent={false}
      />
      <Footer />
    </>
  );
}

export default VideoTheme;
