import { useContext } from 'react';
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from 'react-netlify-forms';
import { useForm } from 'react-hook-form';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { ArtistContext } from 'context/ArtistContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faEnvelope,
  faPhone,
  faLocationDot,
  faDiamondTurnRight,
} from '@fortawesome/free-solid-svg-icons';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import './contact.scss';
import { InputTextarea } from 'primereact/inputtextarea';
import { NavigationContactInfo } from 'types';
import ReactGA from 'react-ga4';

export default function ContactPage() {
  const artist = useContext(ArtistContext);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });
  const netlify = useNetlifyForm({
    name: 'Contact',
    action: '/contact',
    honeypotName: 'bot-field',
    onSuccess: (response: any, context: any) => {
      ('Successfully sent form data to LanzaMe Server');

      console.log('response', response);
      console.log('context', context);
      reset();
    },
  });

  const onSubmit = data => netlify.handleSubmit(null, data);

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  if (!artist) return <div />;
  const {
    isSpanish,
    bookingEmailOne,
    bookingPhoneOne,
    bookingNameOne,
    epkLink,
    siteLibraryJson,
  } = artist;
  console.log(epkLink && epkLink);
  return (
    <>
      <Nav />
      <NetlifyFormProvider {...netlify}>
        <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
          <Honeypot />
          <section className="container flex-column grid mx-auto dark-section">
            <div className="col-10 md:col-6 mx-auto">
              <h1 className="text-2xl md:text-4xl xl:text-6xl text-center mb-0">
                {isSpanish ? 'Contacto' : 'Contact'}
              </h1>

              <div className="form-input-wrapper theme-bg-white border-round p-4">
                {netlify.success && <p>Thanks for contacting us!</p>}
                {netlify.error && (
                  <p>
                    Sorry, we could not reach servers. Because it only works on
                    Netlify, our GitHub demo does not provide a response.
                  </p>
                )}
                <div className="field-wrapper my-4">
                  <span className="p-float-label">
                    <InputText
                      type="email"
                      id="Email"
                      className="w-full theme-border-primary theme-bg-white"
                      {...register('Email', {
                        required: 'Email is required',
                        pattern: {
                          value: EMAIL_REGEX,
                          message: 'Invalid email address',
                        },
                      })}
                    />
                    <label htmlFor="Email">
                      {' '}
                      {isSpanish ? 'Email' : 'Email'}
                    </label>
                  </span>
                  {errors.email && <div>{errors.email.message as string}</div>}
                </div>

                <div className="field-wrapper my-4">
                  <span className="p-float-label">
                    <InputText
                      type="text"
                      id="Name"
                      className="w-full theme-border-primary theme-bg-white "
                      {...register('Name', {
                        required: 'Name is required',
                      })}
                    />
                    <label htmlFor="Name">
                      {isSpanish ? 'Nombre' : 'Name'}
                    </label>
                  </span>
                  {errors.email && <div>{errors.email.message as string}</div>}
                </div>

                <div className="field-wrapper my-4">
                  <span className="p-float-label">
                    <InputText
                      id="phone"
                      className="w-full theme-border-primary theme-bg-white"
                      {...register('Phone', {
                        required: 'Phone is required',
                      })}
                    />
                    <label htmlFor="phone">{isSpanish ? 'Tel' : 'Phone'}</label>
                  </span>
                </div>

                <div className="field-wrapper my-4">
                  <span className="p-float-label">
                    <InputTextarea
                      id="Message"
                      autoResize
                      className="w-full theme-border-primary theme-bg-white"
                      rows={5}
                      cols={30}
                      {...register('Message', {
                        required: 'Message is required',
                      })}
                    />
                    <label htmlFor="Message">
                      {isSpanish ? 'Mensaje' : 'Message'}
                    </label>
                  </span>
                  {/* {errors.email && <div>{errors.email.message}</div>} */}
                </div>

                <div>
                  <Button
                    type="submit"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Interaction',
                        action: `Contact Page - Form Submit`,
                        label: `Contact Page - Form Submit`,
                      });
                    }}
                    label={isSpanish ? 'Enviar' : 'Submit'}
                    className="p-button p-component theme-bg-white theme-border-blk border-1 px-4 md:px-8 py-3 theme-button mx-auto max-w-max block no-underline my-4 font-bold w-full"
                  />
                </div>
              </div>
            </div>
          </section>
        </NetlifyFormComponent>
      </NetlifyFormProvider>
      <section className="container grid mx-auto my-8 dark-section">
        <div className="col-10 md:col-6 mx-autocol-10 md:col-6 mx-auto">
          {siteLibraryJson?.SiteContactItems ? (
            siteLibraryJson?.SiteContactItems.map(
              (contactItem: NavigationContactInfo) => (
                <div className="grid w-full flex align-items-center justify-content-center">
                  <div className="col-12 flex align-items-center flex-column">
                    {!!contactItem.name && (
                      <h4 className="font-bold text-xl m-0 flex flex-column align-items-center text-center justify-content-center mb-2">
                        <span className="block mx-auto">
                          {contactItem.name}
                        </span>
                        <span className="opacity-70 block text-xs mx-auto uppercase">
                          {contactItem.title}
                        </span>
                      </h4>
                    )}
                    {!!contactItem.email && (
                      <p className="flex align-items-center mt-0 mb-2">
                        <FontAwesomeIcon
                          icon={faEnvelope as IconProp}
                          className="fa-fw mr-2"
                        />
                        <span>{contactItem.email}</span>
                      </p>
                    )}
                    {!!contactItem.phone && (
                      <p className="flex align-items-center mt-0 mb-2">
                        <FontAwesomeIcon
                          icon={faPhone as IconProp}
                          className="fa-fw mr-2"
                        />
                        <span>{contactItem.phone}</span>
                      </p>
                    )}
                    {!!contactItem.address && (
                      <div className="flex flex-row align-items-center">
                        <p>
                          <FontAwesomeIcon
                            icon={faLocationDot as IconProp}
                            className="fa-fw mr-2"
                          />
                        </p>
                        <p className="flex flex-column">
                          <span>{contactItem.address}</span>
                          <span>{contactItem.cityStateZip}</span>
                        </p>
                        <a
                          href={contactItem.googleAddressLink}
                          className="text-primary"
                          target="_blank"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faDiamondTurnRight as IconProp}
                            className="fa-fw ml-2"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="col-12 md:col-6 animate-col-width">
                    {!!contactItem.imageOne && (
                      <img
                        src={contactItem.imageOne}
                        alt=""
                        className="block w-12 border-round mb-4"
                      />
                    )}
                  </div>
                </div>
              ),
            )
          ) : (
            <div className="border-1 border-round px-4">
              {!!bookingNameOne && (
                <p className="font-bold">{bookingNameOne}</p>
              )}
              {!!bookingEmailOne && (
                <p className="flex align-items-center">
                  <FontAwesomeIcon
                    icon={faEnvelope as IconProp}
                    className="fa-fw mr-2"
                  />
                  <span>{bookingEmailOne}</span>
                </p>
              )}
              {!!bookingPhoneOne && (
                <p className="flex align-items-center">
                  <FontAwesomeIcon
                    icon={faPhone as IconProp}
                    className="fa-fw mr-2"
                  />
                  <span>{bookingPhoneOne}</span>
                </p>
              )}
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}
