import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function DynamicClass() {
  const { pathname } = useLocation();
  const sanitizedLink = pathname.substring(1).split('/')[0];

  useEffect(() => {
    if (sanitizedLink.length > 1) {
      document.body.classList.add(sanitizedLink);
      return () => {
        document.body.classList.remove(sanitizedLink);
      };
    }
    return undefined;
  }, [sanitizedLink]);

  return null;
}
