import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
// import parse from 'html-react-parser';
import './services.scss';
import { ServicePageType } from 'types';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { Head } from '../../components/Head';

const GET_SERVICES = gql`
  query GET_SERVICES {
    services {
      id
      serviceUrl
      title
      image {
        id
        url
      }
      content {
        raw
        html
        markdown
        text
      }
    }
  }
`;

function Service() {
  const { loading, data } = useQuery(GET_SERVICES);
  if (loading) return <div />;
  const { services } = data;

  return (
    <>
      <Head metaTitle="title" />
      <Nav />
      <section className="service-body">
        {services.map((serviceItem: ServicePageType) => (
          <Link
            key={serviceItem.serviceUrl}
            to={`/service/${serviceItem.serviceUrl}`}>
            <p>{serviceItem.title}</p>
          </Link>
        ))}
      </section>
      <Footer />
    </>
  );
}

export default Service;
