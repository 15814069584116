import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './contentPage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faInstagram,
  faFacebook,
  faTiktok,
  faTwitter,
  faYoutube,
  faLinkedin,
  faSoundcloud,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { ActMembersType, featureSectionComponentType } from 'types';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper';
import { Head } from '../../components/Head';
import 'swiper/css';
import 'swiper/css/effect-cards';

const GET_CONTENT_PAGES = gql`
  query GET_CONTENT_PAGES {
    contentPages {
      id
      contentPageUrl
      title
      subtitle
      heroImage {
        id
        url
      }
      content {
        raw
        html
        markdown
        text
      }
      callToActionText
      callToActionLink
      actMembers {
        memberSlug
        name
        instrument
        memberBookingData
        miniMemberBio
        fullMemberBio {
          html
        }
        imageMemberAvatar {
          id
          url
        }
        emailMemberLink
        epkMemberLink
        facebookMemberLink
        instagramMemberLink
        linkedinMemberLink
        memberBandsInTown
        phoneMemberLink
        primaryMember
        spotifyMemberLink
        tikTokMemberLink
        twitterMemberLink
        websiteMemberLink
        memberSoundcloudLink
        youtubeMemberLink
        memberImageGallery(first: 500) {
          id
          url
        }
        memberMusicReleaseGallery(first: 500) {
          id
          url
        }
      }
      contentFeatureSections(first: 500) {
        featureSectionTitle
        featureSectionSubtitle
        featureSectionHtmlIdWrapper
        featureSectionCssClassNameWrapper
        featureSectionColumnCount
        featureSectionButtonText
        featureSectionButtonLink
        featureSectionBackgroundImage {
          url
        }
        featureSectionContent {
          html
        }
        featureSectionDisplayImage {
          url
        }
      }
      contentPageJson
      contentExternalCtaLink
      contentExternalCtaText
    }
  }
`;

export default function ContentPage() {
  const { contentPageUrlParam } = useParams();
  const { loading, data } = useQuery(GET_CONTENT_PAGES);
  if (loading) return <div />;
  const { contentPages } = data;
  const contentpage = contentPages.find(
    (contentpage: { contentPageUrl: string | undefined }) =>
      contentpage.contentPageUrl === contentPageUrlParam,
  );

  const {
    title,
    subtitle,
    content,
    heroImage,
    callToActionText,
    callToActionLink,
    actMembers,
    contentFeatureSections,
    contentPageJson,
    contentExternalCtaLink,
    contentExternalCtaText,
  } = contentpage;

  console.log(contentPageJson);

  return (
    <>
      <Head metaTitle={title} />
      <Nav />
      <section className="contentPage-body dark-section container my-8 md:mt-6 md:mb-8 mx-auto w-10 lg:w-10 xl:w-10">
        <section
          className="hero-container"
          style={{
            backgroundImage: `url(${heroImage?.url})`,
          }}>
          <div className="hero-text-content-page flex flex-column align-items-center justify-content-center py-4">
            {!!title && (
              <h1 className="text-3xl md:text-5xl xl:text-8xl text-shadow my-0 py-0">
                {title}
              </h1>
            )}
            {!!subtitle && (
              <h2 className="text-shadow my-0 py-0 text-center">{subtitle}</h2>
            )}
          </div>
        </section>
      </section>
      <section className="container my-8 mx-auto w-10 lg:w-10 xl:w-10 dark-section">
        {!!content?.html && (
          <div className="body-parsed-text">{parse(content.html)}</div>
        )}
        {!!callToActionLink && (
          <Link
            to={callToActionLink}
            className="theme-bg-white theme-border-blk border-1 px-4 md:px-8 py-3 theme-button mx-auto max-w-max block no-underline my-4 font-bold w-full">
            {callToActionText}
          </Link>
        )}
        {!!contentExternalCtaLink && (
          <a
            href={contentExternalCtaLink}
            target="_blank"
            className="theme-bg-white theme-border-blk border-1 px-4 md:px-8 py-3 theme-button mx-auto max-w-max block no-underline my-4 font-bold w-full"
            rel="noreferrer">
            {contentExternalCtaText}
          </a>
        )}
      </section>
      {!!actMembers.length && (
        <section className="container my-8 mx-auto w-10 lg:w-10 xl:w-10 dark-section">
          <div className="grid mx-auto">
            {actMembers.map((member: ActMembersType) => (
              <div
                className={`mx-auto ${
                  actMembers.length === 1
                    ? 'col-12 md:col-8'
                    : 'col-12 md:col-6'
                }`}
                key={member.id}>
                <div className="member-card">
                  <img
                    src={member?.imageMemberAvatar?.url}
                    alt={(member.name && member.name) || ''}
                    className=""
                  />
                  <div className="member-card-content px-4">
                    {!!member.name && (
                      <h3 className="text-2xl uppercase mb-2 gradient-text text-center underline">
                        {member.name}
                      </h3>
                    )}
                    {!!member.instrument && (
                      <p className="text-sm mt-0 py-0 mb-0 uppercase text-center">
                        {member.instrument}
                      </p>
                    )}
                    {!!member?.miniMemberBio && (
                      <p className="text-sm mt-0 py-0 mb-2 uppercase text-center">
                        {member.miniMemberBio}
                      </p>
                    )}
                    <nav className="flex flex-row member-card-social-icons align-items-center mx-0 px-0 mt-1 mb-0 justify-content-center">
                      {!!member.instagramMemberLink && (
                        <a
                          href={member.instagramMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 text-center ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faInstagram as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.facebookMemberLink && (
                        <a
                          href={member.facebookMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faFacebook as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.twitterMemberLink && (
                        <a
                          href={member.twitterMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faTwitter as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.youtubeMemberLink && (
                        <a
                          href={member.youtubeMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faYoutube as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.tikTokMemberLink && (
                        <a
                          href={member.tikTokMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faTiktok as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.spotifyMemberLink && (
                        <a
                          href={member.spotifyMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faSpotify as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.memberSoundcloudLink && (
                        <a
                          href={member.memberSoundcloudLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faSoundcloud as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.linkedinMemberLink && (
                        <a
                          href={member.linkedinMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faLinkedin as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.emailMemberLink && (
                        <a
                          href={`mailto:${member.emailMemberLink}`}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faEnvelope as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                      {!!member.phoneMemberLink && (
                        <a
                          href={member.phoneMemberLink}
                          target="_blank"
                          className="max-w-max mr-2 ml-0"
                          rel="noreferrer">
                          <FontAwesomeIcon
                            icon={faPhone as IconProp}
                            className="fa-fw"
                          />
                        </a>
                      )}
                    </nav>
                    {!!member.memberImageGallery && (
                      <Swiper
                        effect="cards"
                        grabCursor
                        modules={[EffectCards]}
                        className="album-swiper my-4">
                        {member.memberImageGallery.map(
                          (memberImageGalleryItem: { url: any }) => (
                            <SwiperSlide key={memberImageGalleryItem.url}>
                              <img
                                src={memberImageGalleryItem.url}
                                alt=""
                                className="mx-auto mb-0 block border-round swiper-image-gallery-item"
                                style={{
                                  objectFit: 'cover',
                                  aspectRatio: 1,
                                }}
                              />
                            </SwiperSlide>
                          ),
                        )}
                      </Swiper>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      )}
      {!!contentFeatureSections && (
        <section className="container dark-section container my-8 mx-auto w-10 lg:w-8 xl:w-6">
          {contentFeatureSections.map(
            (featureSectionComponent: featureSectionComponentType) => (
              <section
                className={`section-component-alternate-wrapper ${featureSectionComponent.featureSectionCssClassNameWrapper}`}
                id={featureSectionComponent.featureSectionHtmlIdWrapper}
                key={featureSectionComponent.featureSectionHtmlIdWrapper}
                style={{
                  backgroundImage:
                    featureSectionComponent.featureSectionBackgroundImage &&
                    `url(${featureSectionComponent.featureSectionBackgroundImage.url})`,
                }}>
                {featureSectionComponent.featureSectionColumnCount === 1 && (
                  <div>
                    {!!featureSectionComponent.featureSectionDisplayImage && (
                      <img
                        src={
                          featureSectionComponent.featureSectionDisplayImage.url
                        }
                        className="w-full block"
                        style={{ objectFit: 'cover', maxHeight: '340px' }}
                        alt={
                          (featureSectionComponent.featureSectionTitle &&
                            featureSectionComponent.featureSectionTitle) ||
                          ''
                        }
                      />
                    )}
                    {!!featureSectionComponent.featureSectionTitle && (
                      <h2>{featureSectionComponent.featureSectionTitle}</h2>
                    )}
                    {!!featureSectionComponent.featureSectionSubtitle && (
                      <h3>{featureSectionComponent.featureSectionSubtitle}</h3>
                    )}
                    {!!featureSectionComponent.featureSectionSubtitle && (
                      <h3>{featureSectionComponent.featureSectionSubtitle}</h3>
                    )}
                    {!!featureSectionComponent.featureSectionContent && (
                      <div className="feature-section-html body-parsed-text mb-4">
                        {parse(
                          featureSectionComponent.featureSectionContent.html,
                        )}
                      </div>
                    )}
                    {!!featureSectionComponent?.featureSectionButtonLink && (
                      <div>
                        {featureSectionComponent?.featureSectionButtonLink?.includes(
                          'http',
                        ) ? (
                          <a
                            href={
                              featureSectionComponent.featureSectionButtonLink
                            }
                            target="_blank"
                            className="theme-border-white text-white border-1 px-4 md:px-6 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full text-2xl mx-auto"
                            rel="noreferrer">
                            {featureSectionComponent.featureSectionButtonText}
                          </a>
                        ) : (
                          <Link
                            to={`${featureSectionComponent.featureSectionButtonLink}`}
                            className="theme-border-white text-white border-1 px-4 md:px-6 py-2 theme-button max-w-max block no-underline my-4 font-bold w-full text-2xl mx-auto">
                            {featureSectionComponent.featureSectionButtonText}
                          </Link>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {featureSectionComponent.featureSectionColumnCount === 2 && (
                  <div className="section-component-alternate grid">
                    <div className="col-12 md:col-6">
                      {!!featureSectionComponent.featureSectionDisplayImage && (
                        <img
                          src={
                            featureSectionComponent.featureSectionDisplayImage
                              .url
                          }
                          className="w-full block"
                          style={{ objectFit: 'cover', maxHeight: '340px' }}
                          alt={
                            (featureSectionComponent.featureSectionTitle &&
                              featureSectionComponent.featureSectionTitle) ||
                            ''
                          }
                        />
                      )}
                    </div>
                    <div className="col-12 md:col-6">
                      {!!featureSectionComponent.featureSectionTitle && (
                        <h2>{featureSectionComponent.featureSectionTitle}</h2>
                      )}
                      {!!featureSectionComponent.featureSectionSubtitle && (
                        <h3>
                          {featureSectionComponent.featureSectionSubtitle}
                        </h3>
                      )}
                      {!!featureSectionComponent.featureSectionSubtitle && (
                        <h3>
                          {featureSectionComponent.featureSectionSubtitle}
                        </h3>
                      )}
                      {!!featureSectionComponent.featureSectionContent && (
                        <div className="feature-section-html body-parsed-text">
                          {parse(
                            featureSectionComponent.featureSectionContent.html,
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </section>
            ),
          )}
        </section>
      )}
      <Footer />
    </>
  );
}
