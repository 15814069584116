import React, { useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import './splashpage.scss';
import { featureSectionComponentType } from 'types';
import Loader from 'components/loader/Loader';
import { Head } from '../../components/Head';
import { ArtistContext } from '../../context/ArtistContext';

const GET_SPLASHPAGES = gql`
  query GET_SPLASHPAGES {
    splashPages {
      splashPageTitle
      splashPageRoute
      splashPageCssWrapper
      splashSplitLayout
      splashPageBackgroundImage {
        id
        url
      }
      splashPageSections {
        featureSectionButtonLink
        featureSectionButtonText
        featureSectionColumnCount
        featureSectionCssClassNameWrapper
        featureSectionHtmlIdWrapper
        featureSectionSubtitle
        featureSectionTitle
        featureSectionContent {
          raw
          html
          markdown
          text
        }
        featureSectionBackgroundImage {
          id
          url
        }
        featureSectionDisplayImage {
          id
          url
        }
      }
    }
  }
`;

export default function SplashPage() {
  const { loading, data } = useQuery(GET_SPLASHPAGES);
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;

  const {
    title,

    isSpanish,
  } = artist;
  if (loading) return <Loader height="80vh" />;
  const splashpageData = data.splashPages[0];
  const {
    splashPageTitle,
    splashPageSections,
    splashPageBackgroundImage,
    splashPageCssWrapper,
  } = splashpageData;
  return (
    <>
      <Head metaTitle={!!splashPageTitle && splashPageTitle} />
      <section
        className={`splash-page-body ${
          splashPageCssWrapper && splashPageCssWrapper
        }`}
        style={{
          backgroundImage:
            splashPageBackgroundImage &&
            `url(${splashPageBackgroundImage.url})`,
        }}>
        {!!splashPageSections && (
          <div className="container flex flex-column md:flex-row align-items-center flex justify-content-space-between w-full">
            {splashPageSections.map(
              (splashPageSection: featureSectionComponentType) => (
                <section
                  className={`splash-page-section ${splashPageSection.featureSectionCssClassNameWrapper}`}
                  id={splashPageSection.featureSectionHtmlIdWrapper}
                  key={splashPageSection.featureSectionHtmlIdWrapper}
                  style={{
                    backgroundImage:
                      splashPageSection.featureSectionBackgroundImage &&
                      `url(${splashPageSection.featureSectionBackgroundImage.url})`,
                  }}>
                  <div className="splash-page-content">
                    <div className="col-12">
                      {splashPageSection?.featureSectionButtonLink?.includes(
                        'http',
                      ) ? (
                        <a
                          href={splashPageSection.featureSectionButtonLink}
                          className="no-underline uppercase font-bold splash-page-link">
                          {!!splashPageSection.featureSectionDisplayImage && (
                            <img
                              src={
                                splashPageSection.featureSectionDisplayImage.url
                              }
                              className="w-full block py-2 md:py-8"
                              style={{
                                objectFit: 'cover',
                              }}
                              alt={
                                (splashPageSection.featureSectionTitle &&
                                  splashPageSection.featureSectionTitle) ||
                                ''
                              }
                            />
                          )}
                          {!!splashPageSection.featureSectionTitle && (
                            <p className="text-center theme-bg-primary px-4 py-2">
                              {splashPageSection.featureSectionTitle}
                            </p>
                          )}
                        </a>
                      ) : (
                        <div>
                          {!!splashPageSection.featureSectionButtonLink && (
                            <Link
                              to={splashPageSection.featureSectionButtonLink}
                              className="no-underline uppercase font-bold splash-page-link">
                              {!!splashPageSection.featureSectionDisplayImage && (
                                <img
                                  src={
                                    splashPageSection.featureSectionDisplayImage
                                      .url
                                  }
                                  className="w-full block"
                                  style={{
                                    objectFit: 'cover',
                                  }}
                                  alt={
                                    (splashPageSection.featureSectionTitle &&
                                      splashPageSection.featureSectionTitle) ||
                                    ''
                                  }
                                />
                              )}
                              {!!splashPageSection.featureSectionTitle && (
                                <p className="text-center theme-bg-primary px-4 py-2">
                                  {splashPageSection.featureSectionTitle}
                                </p>
                              )}
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              ),
            )}
          </div>
        )}
        <section className="container mx-auto dark-section mb-0">
          <p className="col-12 text-center my-auto mx-auto text-xs md:text-base text-white opacity-80">
            {`© ${new Date().getFullYear()} ${!!title && title} ${
              isSpanish ? 'Todos Derechos Reservados' : 'All Rights Reserved'
            }.`}
          </p>
        </section>
      </section>
    </>
  );
}
