import React from 'react';
import './blogs.scss';
import BlogGridSlider from 'components/blogGridSlider/BlogGridSlider';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';

function Blogs() {
  return (
    <>
      <Nav />
      <section className="container dark-section">
        <BlogGridSlider />
      </section>
      <Footer />
    </>
  );
}

export default Blogs;
