import React from 'react';
import { gql, useQuery } from '@apollo/client';
import './videos.scss';

const GET_VIDEOS = gql`
  query GET_VIDEOS {
    videos {
      id
      title
      youtubeId
    }
  }
`;

function VideoHomePage() {
  const { loading, data } = useQuery(GET_VIDEOS);
  if (loading) return <div />;
  const { videos } = data;

  return (
    <div>
      {!!videos.length && (
        <div className="my-6">
          <section className="container dark-section">
            {videos.map(videoItem => (
              <div key={videoItem.youtubeId} className="w-10 mx-auto">
                {!!videoItem.title && (
                  <h3 className="text-center font-400">{videoItem.title}</h3>
                )}
                <div className="video-container px-4 mx-auto">
                  {!!videoItem.youtubeId && (
                    <iframe
                      src={`https://www.youtube.com/embed/${videoItem.youtubeId}`}
                      width="560"
                      height="315"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="video"
                    />
                  )}
                </div>
              </div>
            ))}
          </section>
        </div>
      )}
    </div>
  );
}

export default VideoHomePage;
