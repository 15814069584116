import React from 'react';
import { ProgressBar } from 'primereact/progressbar';

interface LoaderProps {
  height: any;
}

export default function Loader(props: LoaderProps) {
  const { height } = props;
  return (
    <div className="container" style={height && { minHeight: `${height}` }}>
      <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
    </div>
  );
}
