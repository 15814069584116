/* eslint-disable no-alert */
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';

interface PaypalProps {
  invoiceName?: string;
  invoiceNumber?: string;
  invoiceTotal?: string;
}

export default function Paypal(props: PaypalProps) {
  const { invoiceName, invoiceNumber, invoiceTotal } = props;
  console.log('data', invoiceName, invoiceNumber, invoiceTotal);
  console.log('total', invoiceTotal);

  return (
    <PayPalScriptProvider options={{ 'client-id': 'test' }}>
      <PayPalButtons
        style={{ layout: 'horizontal' }}
        createOrder={(data, actions) =>
          actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: `${invoiceTotal}`,
                },
                invoice_id: invoiceNumber,
              },
            ],
          })
        }
        onApprove={async (data, actions) => {
          const details = await actions?.order?.capture();
          const name = details?.payer?.name?.given_name;

          alert(`Transaction completed by ${name}`);
        }}
      />
    </PayPalScriptProvider>
  );
}
