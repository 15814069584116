/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import reportWebVitals from './reportWebVitals';
import App from './App';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'primereact/resources/themes/luna-amber/theme.css';
import './index.scss';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GKEY,
  cache: new InMemoryCache(),
});
const stripePromise = loadStripe('pt_01GWE23DVHTJZX7SX0JQ6F2H0G');

const root = ReactDOM.createRoot(document.getElementById('bri') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <HelmetProvider>
        <BrowserRouter>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </BrowserRouter>
      </HelmetProvider>
    </ApolloProvider>
  </React.StrictMode>,
);

reportWebVitals();
