import React, { useContext } from 'react';
import { ArtistContext } from 'context/ArtistContext';
import './events.scss';
import EventGridSlider from 'components/eventGridSlider/EventGridSlider';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';

export default function EventsPage() {
  const artist = useContext(ArtistContext);

  if (!artist) return <div />;

  const { isSpanish } = artist;

  return (
    <>
      <Nav />
      <section className="container dark-section">
        <div className="relative w-10 mx-auto mt-2 mb-4 md:my-8">
          <div className="gradient-bkg">
            <div className="outline-text-wrapper py-6 px-4 w-full">
              <h1 className="outline-text text-center text-2xl text-center md:text-4xl pb-0 my-0">
                {isSpanish ? 'Eventos' : 'Events'}
              </h1>
            </div>
          </div>
        </div>
        <EventGridSlider />
      </section>
      <Footer />
    </>
  );
}
