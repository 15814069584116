import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import './service.scss';
import { ServicePageType } from 'types';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { Head } from '../../components/Head';

const GET_SERVICES = gql`
  query GET_SERVICES {
    services {
      id
      serviceUrl
      title
      image {
        id
        url
      }
      content {
        raw
        html
        markdown
        text
      }
    }
  }
`;

function Service() {
  const { serviceLink } = useParams();
  const { loading, data } = useQuery(GET_SERVICES);
  if (loading) return <div />;
  const { services } = data;

  const service = services.find(
    (service: ServicePageType) => service.serviceUrl === serviceLink,
  );
  const { serviceUrl, title, content, image } = service;
  return (
    <>
      <Head metaTitle={title} />
      <Nav />
      <section className="service-body">
        {!!title && <h1>{title}</h1>}
        {!!serviceUrl && <p>{serviceUrl}</p>}
        {!!image?.url && <img src={image.url} alt="" className="src" />}
        {!!content.html && <div>{parse(content.html)}</div>}
      </section>
      <Footer />
    </>
  );
}

export default Service;
