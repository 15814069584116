import React, { ReactElement, useContext } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './FeatureAlbum.scss';
import { AlbumType } from 'types';
import { ArtistContext } from 'context/ArtistContext';
import { Fade, Slide } from 'react-awesome-reveal';
import useViewport from 'hooks/useViewport';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import ReactGA from 'react-ga4';

const GET_ALBUMS = gql`
  query GET_ALBUMS {
    albums(first: 100, orderBy: releaseDate_DESC) {
      id
      albumUrl
      title
      releaseDate
      smartBuyLink
      featureHomePage
      albumCover {
        id
        url(
          transformation: {
            image: { resize: { width: 425, height: 425 } }
            document: { output: { format: webp } }
          }
        )
      }
      trackListing {
        raw
        html
        markdown
        text
      }
    }
  }
`;

interface FeatureAlbumProps {
  albumMap?: boolean;
}

function FeatureAlbum(props: FeatureAlbumProps): ReactElement {
  const { loading, data } = useQuery(GET_ALBUMS);
  const artist = useContext(ArtistContext);
  const { isMobile } = useViewport();
  if (!artist) return <div />;
  if (loading) return <div />;
  const { albums } = data;
  const { isSpanish } = artist;
  const { albumMap } = props;

  return (
    <>
      {albums
        .filter(
          (album: { featureHomePage: boolean }) =>
            album.featureHomePage === true,
        )
        .map((albumItem: AlbumType) => (
          <section
            className="gradient-bkg px-2 mx-0 -mt-2"
            key={albumItem.albumUrl}>
            <section className="container py-5">
              <div className="grid">
                <div className="col-12">
                  {!!albumItem.title && (
                    <Slide className="col-12" direction="up" triggerOnce>
                      <h3 className="text-3xl md:text-6xl mt-0 mb-4 text-center uppercase">
                        {albumItem.title}
                      </h3>
                    </Slide>
                  )}
                </div>
                <div className="col-12 md:col-8  mx-auto">
                  {!!albumItem.albumUrl && (
                    <Link
                      to={`/music/${albumItem.albumUrl}`}
                      onClick={() =>
                        ReactGA.event({
                          category: 'Link',
                          action: albumItem.title,
                          label: albumItem.title,
                        })
                      }
                      className="mx-auto max-w-max block no-underline">
                      {!!albumItem.albumCover?.url && (
                        <Slide className="w-full" direction="up" triggerOnce>
                          <img
                            src={albumItem.albumCover.url}
                            alt={(albumItem.title && albumItem.title) || ''}
                            className="mx-auto mb-0 w-12 block theme-box-shadow border-round"
                            style={{
                              maxHeight: '400px',
                              maxWidth: '400px',
                              objectFit: 'cover',
                              aspectRatio: 1,
                            }}
                          />
                        </Slide>
                      )}
                    </Link>
                  )}
                </div>
                <div className="col-12 md:col-8 mx-auto my-auto">
                  {!!albumItem.trackListing && (
                    <div className="parse-block track-list text-center">
                      {parse(albumItem.trackListing.html)}
                    </div>
                  )}
                  {!!albumItem.smartBuyLink && (
                    <a
                      href={albumItem.smartBuyLink}
                      target="_blank"
                      rel="noreferrer"
                      className="theme-bg-white theme-border-blk border-1 px-8 px-4 theme-button mx-auto max-w-max block no-underline">
                      {isSpanish ? 'Escucha' : 'Listen'}
                    </a>
                  )}
                  {!!albumItem.albumBuyLink && (
                    <a
                      href={albumItem.albumBuyLink}
                      onClick={() =>
                        ReactGA.event({
                          category: 'Link',
                          action: `Visit ${albumItem.title}`,
                          label: albumItem.title,
                        })
                      }
                      target="_blank"
                      rel="noreferrer"
                      className="theme-bg-white theme-border-blk border-1 px-8 px-4 theme-button mx-auto max-w-max block no-underline">
                      {isSpanish ? 'Escucha' : 'Listen'}
                    </a>
                  )}
                </div>
              </div>
            </section>
          </section>
        ))}
      {!!albumMap && (
        <section className="gradient-bkg px-2 mx-0 -mt-2">
          <section className="container py-5 feature-album-slider-wrapper">
            <h2 className="text-6xl text-center">
              {isSpanish ? 'Música' : 'Music'}
            </h2>
            {isMobile ? (
              <Swiper
                effect="coverflow"
                grabCursor
                centeredSlides
                slidesPerView="auto"
                coverflowEffect={{
                  rotate: 50,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                }}
                pagination
                modules={[EffectCoverflow, Pagination]}
                className="album-swiper">
                {albums.map((albumItem: AlbumType) => (
                  <SwiperSlide key={albumItem.albumUrl}>
                    <Link
                      to={`/music/${albumItem.albumUrl}`}
                      onClick={() =>
                        ReactGA.event({
                          category: 'Link',
                          action: `Visit ${albumItem.title}`,
                          label: albumItem.title,
                        })
                      }
                      className="max-w-max no-underline">
                      {!!albumItem.albumCover?.url && (
                        <img
                          src={albumItem.albumCover.url}
                          alt={(albumItem.title && albumItem.title) || ''}
                          className="mx-auto mb-0 block border-round"
                          style={{
                            maxHeight: '400px',
                            objectFit: 'cover',
                            aspectRatio: 1,
                          }}
                        />
                      )}
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="grid">
                {albums.map((albumItem: AlbumType) => (
                  <div
                    className="col-8 col:4 lg:col-4 xl:col-2 mx-auto animate-col-width"
                    key={albumItem.albumUrl}>
                    <Fade
                      className="col-12"
                      direction="up"
                      triggerOnce
                      cascade
                      duration={750}>
                      {!!albumItem.albumUrl && (
                        <Link
                          to={`/music/${albumItem.albumUrl}`}
                          onClick={() =>
                            ReactGA.event({
                              category: 'Link',
                              action: `Visit ${albumItem.title}`,
                              label: albumItem.title,
                            })
                          }
                          className="mx-auto max-w-max block no-underline album-map-grid-item">
                          {!!albumItem.albumCover?.url && (
                            <img
                              src={albumItem.albumCover.url}
                              alt={(albumItem.title && albumItem.title) || ''}
                              className="mx-auto mb-0 w-12 block theme-box-shadow border-round"
                              style={{
                                maxHeight: '400px',
                                maxWidth: '400px',
                                objectFit: 'cover',
                                aspectRatio: 1,
                              }}
                            />
                          )}
                        </Link>
                      )}
                      <h3 className="text-xs mt-0 mb-4 text-center uppercase">
                        {albumItem.title}
                      </h3>
                    </Fade>
                  </div>
                ))}
              </div>
            )}
          </section>
        </section>
      )}
    </>
  );
}

export default FeatureAlbum;
