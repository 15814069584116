import React, { useContext, useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams, Link } from 'react-router-dom';
import parse from 'html-react-parser';
import './album.scss';
import { ArtistContext } from 'context/ArtistContext';
import { AlbumType } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import Moment from 'react-moment';
import ReactGA from 'react-ga4';
import { Head } from '../../components/Head';
import Loader from '../../components/loader/Loader';

const GET_ALBUMS = gql`
  query GET_ALBUMS {
    albums(first: 100) {
      id
      albumUrl
      title
      releaseDate
      smartBuyLink
      albumBuyLink
      albumCover {
        id
        url
      }
      trackListing {
        raw
        html
        markdown
        text
      }
      albumJsonData
      iFramePlayer
    }
  }
`;

function Album() {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const stickyCallBak = () => {
    const scrollTop = window.scrollY;
    scrollTop >= 25 ? setIsScrolled(true) : setIsScrolled(false);
  };
  useEffect(() => {
    window.addEventListener('scroll', stickyCallBak);

    return () => {
      window.removeEventListener('scroll', stickyCallBak);
    };
  });
  const { albumLink } = useParams();
  const { loading, data } = useQuery(GET_ALBUMS);
  const artist = useContext(ArtistContext);
  if (loading) return <Loader height="60vh" />;
  const { albums } = data;
  const { isSpanish } = artist;

  const album = albums.find((album: AlbumType) => album.albumUrl === albumLink);
  const {
    title,
    releaseDate,
    albumCover,
    trackListing,
    smartBuyLink,
    albumJsonData,
    iFramePlayer,
    albumBuyLink,
  } = album;

  return (
    <>
      <Head metaTitle={title} />
      <Nav />
      <section
        className={`animate-col-width album-body w-10 md:w-8 lg:w-6 xl:w-3 ${
          isScrolled ? 'lg:w-7 xl:w-4' : 'lg:w-6 xl:w-3'
        } mx-auto block my-4 p-4 dark-section`}>
        {/* <section className="album-body w-10 md:w-8 lg:w-6 xl:w-3 mx-auto block my-4 p-4 dark-section"> */}
        <div className="flex flex-column w-full">
          <div className="w-full mx-auto text-center my-2 md:mt-0 mb-2">
            <Link
              to="/music"
              onClick={() =>
                ReactGA.event({
                  category: 'Link',
                  action: 'Visit: Music Page',
                  label: 'Visit: Music Page',
                })
              }
              className="text-link uppercase no-underline justify-content-center max-w-max">
              <FontAwesomeIcon
                icon={faArrowLeft as IconProp}
                className="fa-fw mr-2"
              />
              <span>{isSpanish ? 'Toda La Música' : 'All Music'}</span>
            </Link>
          </div>
          {!!albumCover?.url && (
            <img
              src={albumCover.url}
              alt=""
              className="max-w-max block mx-auto w-full"
            />
          )}
          {!!smartBuyLink && (
            <a
              href={smartBuyLink}
              onClick={() =>
                ReactGA.event({
                  category: 'Conversion',
                  action: `Visit: Listen ${title} Link: ${smartBuyLink}`,
                  label: `Visit: Listen ${title} Link: ${smartBuyLink}`,
                })
              }
              target="_blank"
              rel="noreferrer"
              className="theme-bg-white theme-border-blk border-1 theme-button mx-auto max-w-max block no-underline mt-4 mb-2 w-full text-center px-4">
              {isSpanish ? 'Escucha' : 'Listen'}
            </a>
          )}
          {!!albumBuyLink && (
            <a
              href={albumBuyLink}
              onClick={() =>
                ReactGA.event({
                  category: 'Conversion',
                  action: `Visit: Listen ${title} Link: ${albumBuyLink}`,
                  label: `Visit: Listen ${title} Link: ${albumBuyLink}`,
                })
              }
              target="_blank"
              rel="noreferrer"
              className="theme-bg-white theme-border-blk border-1 theme-button mx-auto max-w-max block no-underline mt-4 mb-2 w-full text-center px-4">
              {isSpanish ? 'Escucha' : 'Listen'}
            </a>
          )}
          {!!title && <h1 className="text-center mb-0">{title}</h1>}
          {!!releaseDate && (
            <p className="text-center text-sm my-0 opacity-70">
              <Moment format="MMM DD, YYYY">{releaseDate}</Moment>
            </p>
          )}
          {!!trackListing && (
            <div className="track-list">{parse(trackListing.html)}</div>
          )}
          {!!iFramePlayer && <div className="my-8">{parse(iFramePlayer)}</div>}
          {!!albumJsonData?.YoutubeID && (
            <div className="mx-auto w-full block">
              <iframe
                src={`https://www.youtube.com/embed/${albumJsonData?.YoutubeID}`}
                width="100%"
                height="315"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="video"
              />
            </div>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Album;
