import { ArtistContext } from 'context/ArtistContext';
import { useContext } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Helmet as ReactHelmet } from 'react-helmet-async';

// const DEFAULT_DESCRIPTION =
//   'A blockchain marketplace platform for people passionate about health.';
// const DEFAULT_TITLE = 'crwdmarket';

/**
 * @param title specific webpage title replaces default 'crwdmarket'
 * @param description custom description to replace default
 * @param link page path ex: /product
 * @param keywords postfix specific meta keywords to the mainKeyWords
 * @param imageCard image to replace defaultImageCard for og:image
 * @param largeTwitterCard
 * @param addPostfixTitle If true, adds POSTFIX_TITLE to the end of the title. Ex/ "Vitamins - Advanced Medicine Marketplace "
 * @param noIndex If true: Website page can be indexed, but never shown in any Google search results. ex/ Used on Page Not Found
 * @param children add extra meta tags
 * @param HelmetPropTypes test
 */

export function Head(props) {
  // // TODO: Remove this once tailwind styling is updated * For DarkMode:
  // const [isDarkFromLocalStorage] = useLocalStorage<boolean>(
  //   'isDark',
  //   localStorage.getItem('isDark') === 'true',
  // );

  // // default values:
  // const DOMAIN = meta.protocolAndDomain
  //   ? meta.protocolAndDomain
  //   : 'https://cart.crwd.market/';

  // const MAIN_KEYWORDS = meta.mainKeyWords
  //   ? meta.mainKeyWords
  //   : 'blockchain ecosystem';

  // const DEFAULT_IMAGE_CARD = meta.defaultImageCard
  //   ? meta.defaultImageCard
  //   : crwdmarket_logo;

  // DEFAULT_TITLE = meta.title || 'crwdmarket';
  // const POSTFIX_TITLE = ` - ${meta.title}` || ' - crwdmarket';

  // let metaTitle: string | undefined;

  // if (addPostfixTitle) {
  //   metaTitle = title + POSTFIX_TITLE;
  // } else {
  //   metaTitle = title;
  // }

  // const metaDescription = description ?? DEFAULT_DESCRIPTION;
  // const metaLink = DOMAIN + link;

  // const metaKeywords = keywords?.length
  //   ? `${MAIN_KEYWORDS}, ${keywords}`
  //   : MAIN_KEYWORDS;

  // let metaImageCard: string;

  // if (imageCard) {
  //   if (imageCard.startsWith('https' || 'http')) {
  //     metaImageCard = imageCard;
  //   } else {
  //     metaImageCard = DOMAIN + imageCard;
  //   }
  // } else {
  //   metaImageCard = DOMAIN + DEFAULT_IMAGE_CARD;
  // }

  // const metaRobots = noIndex ? 'noindex, nofollow' : 'index, follow';
  // const twitterCardType = largeTwitterCard ? 'summary_large_image' : 'summary';
  const { children, metaTitle } = props;
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;
  const { title } = artist;
  return (
    <ReactHelmet>
      <html lang="en" />
      {!!metaTitle && <title>{title || metaTitle}</title>}
      {/* <link rel="shortcut icon" href={favicon.url} /> */}
      <script src="https://widget.bandsintown.com/main.min.js" />
      {/* <link rel="icon" type="image/ico" href={favicon.url} sizes="16x16" /> */}

      {/* 

      {meta?.appleTouchIcon ? (
        <link rel="apple-touch-icon" href={meta?.appleTouchIcon} />
      ) : null}


      {metaImageCard ? (
        <meta property="og:image" content={metaImageCard} />
      ) : null}
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={metaLink} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />

    

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={metaLink} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="robots" content={metaRobots} />
     
      <meta
        property="twitter:site"
        title={meta.twitterUsername ? meta.twitterUsername : ''}
      />
      <meta property="twitter:description" title={metaDescription} />
      <meta property="twitter:title" title={metaTitle} />
      <meta
        property="twitter:creator"
        content={meta.twitterUsername ? meta.twitterUsername : ''}
      />
      <meta property="twitter:card" content={twitterCardType} />
      <meta property="twitter:image" content={metaImageCard} />
      <meta property="referrer" content="origin-when-crossorigin" /> */}

      {children}
    </ReactHelmet>
  );
}
