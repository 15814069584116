import { Routes, Route } from 'react-router-dom';
import VideosPage from 'pages/videospage';
import EventsPage from 'pages/events/EventsPage';
import EventPage from 'pages/event/EventPage';
import ArtistsPage from 'pages/artists/ArtistsPage';
import ArtistPage from 'pages/artist/ArtistPage';
import HomeLandingPage from 'pages/homelandingpage';
import { ArtistProvider } from 'context/ArtistContext';
import HomePageRender from 'utils/HomePageRender';
import HomePage from 'pages/home';
import ContactPage from 'pages/contact/ContactPage';
import GoogleAnalytics from 'components/GoogleAnalytics';
import Blog from './pages/blog/index';
import Service from './pages/service/index';
import Services from './pages/services/Services';
import Blogs from './pages/blogs/Blogs';
import Album from './pages/album/index';
import Albums from './pages/albums/Albums';
import DynamicClass from './utils/DynamicClass';
import ScrollToTop from './utils/ScrollToTop';
import ContentPage from './pages/contentpage/index';
import BookingPage from './pages/booking/BookingPage';

function App() {
  return (
    <ArtistProvider>
      <DynamicClass />
      <ScrollToTop />
      <GoogleAnalytics />
      <Routes>
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/:contentPageUrlParam" element={<ContentPage />} />
        <Route path="/blog/:blogLink" element={<Blog />} />
        <Route path="/music" element={<Albums />} />
        <Route path="/musica" element={<Albums />} />
        <Route path="/music/:albumLink" element={<Album />} />
        <Route path="/musica/:albumLink" element={<Album />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service/:serviceLink" element={<Service />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/booking" element={<BookingPage />} />
        <Route path="/videos" element={<VideosPage />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/artists" element={<ArtistsPage />} />
        <Route path="/artist/:artistPageUrlParam" element={<ArtistPage />} />
        <Route
          path="/artist/label/:artistPageUrlParam"
          element={<ArtistPage />}
        />
        <Route path="/event/:eventPageUrlParam" element={<EventPage />} />
        <Route path="/" element={<HomePageRender />} />
        <Route path="/entertainment" element={<HomePage />} />
        <Route path="/music-label" element={<HomeLandingPage />} />
      </Routes>
    </ArtistProvider>
  );
}

export default App;
