import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { BlogType } from 'types';
import useViewport from 'hooks/useViewport';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../loader/Loader';

interface BlogGridSliderProps {
  fromHomePage?: boolean;
  blogHeader?: string;
}

const GET_BLOGS = gql`
  query GET_BLOGS {
    blogs {
      id
      blogUrl
      title
      excerpt
      image {
        id
        url
      }
      content {
        raw
        html
        markdown
        text
      }
    }
  }
`;

export default function BlogGridSlider(props: BlogGridSliderProps) {
  const { loading, data } = useQuery(GET_BLOGS);
  const { isMobile } = useViewport();
  if (loading) return <Loader height="60vh" />;
  const { blogs } = data;
  if (!blogs) return <div />;
  const { fromHomePage, blogHeader } = props;
  return (
    <div>
      {!!blogs && (
        <div className="blog-wrapper">
          {!fromHomePage ? (
            <>
              <h2 className="text-center">
                {(!!blogHeader && blogHeader) || 'Blogs'}
              </h2>
              <div className="grid">
                {blogs?.map((blogItem: BlogType) => (
                  <div
                    className="col-12 md:col-6 mx-auto"
                    key={blogItem.blogUrl}>
                    <Link
                      to={`/blog/${blogItem.blogUrl}`}
                      className="blog-card mx-auto">
                      {!!blogItem.image?.url && (
                        <img
                          src={blogItem.image.url}
                          alt=""
                          className="w-full mb-0"
                        />
                      )}
                      <p className="font-bold mb-1 pb-0">{blogItem.title}</p>
                      <p className="font-sm line-clamp-2 font-light mt-0">
                        {blogItem.excerpt}
                      </p>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div>
              {isMobile ? (
                <>
                  <h2 className="text-center">
                    {(!!blogHeader && blogHeader) || 'Blogs'}
                  </h2>
                  <div className="grid">
                    {blogs?.slice(0, 3).map((blogItem: BlogType) => (
                      <div
                        className="col-12 md:col-6 mx-auto"
                        key={blogItem.blogUrl}>
                        <Link
                          to={`/blog/${blogItem.blogUrl}`}
                          className="blog-card">
                          {!!blogItem.image?.url && (
                            <img
                              src={blogItem.image.url}
                              alt=""
                              className="w-full mb-0"
                            />
                          )}
                          <p className="font-bold mb-1 pb-0">
                            {blogItem.title}
                          </p>
                          <p className="font-sm line-clamp-2 font-light mt-0">
                            {blogItem.excerpt}
                          </p>
                        </Link>
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <h2 className="text-center">
                    {(!!blogHeader && blogHeader) || 'Blogs'}
                  </h2>
                  <div className="grid blog-swiper">
                    <Swiper
                      slidesPerView={3}
                      spaceBetween={30}
                      pagination={{
                        clickable: true,
                      }}
                      modules={[Pagination]}
                      className="mySwiper">
                      {blogs.map((blogItem: BlogType) => (
                        <SwiperSlide
                          className="w-8 md:w-4 mr-4"
                          key={blogItem.blogUrl}>
                          <Link
                            to={`/blog/${blogItem.blogUrl}`}
                            className="blog-card">
                            {!!blogItem.image?.url && (
                              <img
                                src={blogItem.image.url}
                                alt=""
                                className="w-full mb-0"
                              />
                            )}
                            <p className="font-bold mb-1 pb-0">
                              {blogItem.title}
                            </p>
                            <p className="font-sm line-clamp-2 font-light mt-0">
                              {blogItem.excerpt}
                            </p>
                          </Link>
                        </SwiperSlide>
                      ))}
                      <SwiperSlide className="w-8 md:w-4 mr-4 h-full flex blog-end-wrapper">
                        <Link
                          to="/blogs"
                          className="text-link blog-end flex align-items-center jutsify-content-center w-full h-full">
                          <p className="flex align-items-center flex-row my-1">
                            <span>
                              {(!!blogHeader && blogHeader) || 'Blogs'}
                            </span>
                            <FontAwesomeIcon
                              icon={faArrowRight as IconProp}
                              className="fa-fw ml-2 my-0"
                            />
                          </p>
                        </Link>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
