import YouTubePlaylist from '@codesweetly/react-youtube-playlist';
import Footer from 'components/footer/Footer';
import Nav from 'components/nav/Nav';
import { ArtistContext } from 'context/ArtistContext';
import { useContext } from 'react';

export default function VideosPage() {
  const artist = useContext(ArtistContext);
  if (!artist) return <div />;

  const { youtubePlaylistId } = artist;

  if (!youtubePlaylistId)
    return (
      <>
        <Nav />
        <section className="container dark-section flex align-items-center justify-conent-center mx-auto">
          <p className="mx-auto text-center">No Videos</p>
        </section>
        <Footer />
      </>
    );

  return (
    <>
      <Nav />
      <section className="contentPage-body dark-section container my-8 mx-auto w-10 lg:w-9">
        <h1 className="text-6xl text-center">Videos</h1>
        {!!youtubePlaylistId && !!process.env.REACT_APP_YOUTUBE_API && (
          <YouTubePlaylist
            apiKey={process.env.REACT_APP_YOUTUBE_API}
            playlistId={youtubePlaylistId}
            uniqueName="VideosPage"
          />
        )}
      </section>
      <Footer />
    </>
  );
}
