import React from 'react';
import { gql, useQuery } from '@apollo/client';
import './videos.scss';

const GET_VIDEOS = gql`
  query GET_VIDEOS {
    videos {
      id
      title
      youtubeId
    }
  }
`;

function VideoHomePage() {
  const { loading, data } = useQuery(GET_VIDEOS);
  if (loading) return <div />;
  const { videos } = data;

  return (
    <div>
      {!!videos.length && (
        <section className="container dark-section">
          <h2 className="text-6xl text-center">Videos</h2>

          {videos.map(videoItem => (
            <div key={videoItem.youtubeId} className="w-10 mx-auto">
              {!!videoItem.title && (
                <h3 className="text-center font-400">{videoItem.title}</h3>
              )}
              <div className="video-container px-4 mx-auto">
                {!!videoItem.youtubeId && (
                  <iframe
                    src={`https://www.youtube.com/embed/${videoItem.youtubeId}`}
                    width="560"
                    height="315"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="video"
                  />
                )}
              </div>
            </div>
          ))}
        </section>
      )}
    </div>
  );
}

export default VideoHomePage;
